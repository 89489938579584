import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import archiveReducer from "./archiveSlice";
import marriageFormReducer from "./forms/marriage-form/marriageFormSlice";
import spacesFormReducer from "./forms/spaces-form/spacesFormSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    archive: archiveReducer,
    marriageForm: marriageFormReducer,
    spacesForm: spacesFormReducer,
  },
});

store.subscribe(() => {
  localStorage.setItem("authState", JSON.stringify(store.getState().auth));
});
