import React from "react";
import { FileText, XCircle } from "lucide-react";
import InfoTooltip from "./InfoTooltip";
import { Progress } from "components/shadcn/Progress";
import { useDispatch, useSelector } from "react-redux";
import { setMultipleFileError } from "store/forms/marriage-form/marriageFormSlice";
import {
  uploadMultipleIdDocuments,
  deleteAllMultipleIdDocuments,
} from "store/forms/marriage-form/thunks";
import { MAX_FILE_SIZE, ACCEPTED_FILE_TYPES } from "constants/formsConstants";
import {
  handleFileDrop,
  handleFileInputChange,
  truncateFileName,
} from "utils/uploadUtils";

const UploadMultipleFiles = ({ labels, lang }) => {
  const dispatch = useDispatch();
  const fileError = useSelector(
    (state) => state.marriageForm.uploadMultipleFiles.fileError,
  );
  const multipleFilesState = useSelector(
    (state) => state.marriageForm.uploadMultipleFiles,
  );
  const { files, isDeletingAll } = multipleFilesState;

  const isRTL = lang === "ku";

  const onFilesUpdate = (newFiles) => {
    dispatch(uploadMultipleIdDocuments({ newFiles }));
  };

  const clearAllFiles = () => {
    dispatch(deleteAllMultipleIdDocuments())
      .unwrap()
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div
      onDrop={(e) =>
        handleFileDrop(e, {
          dispatch,
          setErrorAction: setMultipleFileError,
          files: files.map((f) => f.fileName),
          maxFiles: 3,
          acceptedTypes: ACCEPTED_FILE_TYPES,
          maxSize: MAX_FILE_SIZE,
          onFilesUpdate,
        })
      }
      onDragOver={(e) => e.preventDefault()}
      style={{
        background: "#FAFAFA",
        border: "2px dashed #ccc",
        borderRadius: "8px",
        padding: "20px",
        textAlign: isRTL ? "right" : "center",
        cursor: files.length < 3 && !isDeletingAll ? "pointer" : "not-allowed",
        position: "relative",
        width: "100%",
        minHeight: "200px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        direction: isRTL ? "rtl" : "ltr",
      }}
      onClick={() => {
        if (files.length < 3 && !isDeletingAll) {
          document.getElementById("multi-file-upload").click();
        }
      }}
    >
      <input
        type="file"
        multiple
        accept={ACCEPTED_FILE_TYPES.join(",")}
        onChange={(e) =>
          handleFileInputChange(e, {
            dispatch,
            setErrorAction: setMultipleFileError,
            files: files.map((f) => f.fileName),
            maxFiles: 3,
            acceptedTypes: ACCEPTED_FILE_TYPES,
            maxSize: MAX_FILE_SIZE,
            onFilesUpdate,
          })
        }
        style={{ display: "none" }}
        id="multi-file-upload"
      />

      {files.length > 0 && !isDeletingAll && (
        <XCircle
          size={30}
          onClick={(e) => {
            e.stopPropagation();
            clearAllFiles();
          }}
          style={{
            position: "absolute",
            top: "10px",
            [isRTL ? "left" : "right"]: "10px",
            cursor: "pointer",
          }}
        />
      )}

      {isDeletingAll ? (
        <p>{labels.deletingAllFiles}</p>
      ) : files.length === 0 ? (
        <>
          <FileText
            size={40}
            color="#6B7280"
            style={{ marginBottom: "10px" }}
          />
          <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
            {labels.uploadSupportingDocuments}
          </p>
          <p style={{ fontSize: "10px", color: "#6B7280" }}>
            {labels.uploadSupportingDocumentsNote}
          </p>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            flexWrap: "wrap",
            width: "100%",
            paddingTop: "10px",
            gap: "10px",
          }}
        >
          {files.map((fileObj, index) => (
            <div
              key={`${fileObj.fileName}-${index}`}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                width: "30%",
                padding: "10px",
                background: "#FFF",
                border: "1px solid #ccc",
                borderRadius: "4px",
                position: "relative",
              }}
            >
              <FileText size={30} color="#6B7280" />
              <span
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "100%",
                  margin: "5px 0",
                  fontSize: "12px",
                  textAlign: isRTL ? "right" : "center",
                }}
              >
                {truncateFileName(fileObj.fileName)}
              </span>
              {!fileObj.isUploaded &&
                fileObj.error === null &&
                !fileObj.isDeleting && (
                  <Progress value={fileObj.uploadProgress} className="w-full" />
                )}
              {fileObj.isUploaded && (
                <p style={{ fontSize: "12px", color: "green" }}>
                  {labels.uploaded}
                </p>
              )}
              {fileObj.error && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  {fileObj.error}
                </p>
              )}
              {fileObj.isDeleting && (
                <p style={{ fontSize: "12px", color: "orange" }}>
                  {labels.deleting}
                </p>
              )}
            </div>
          ))}
        </div>
      )}

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          [isRTL ? "left" : "right"]: "10px",
        }}
      >
        <InfoTooltip
          content={
            <div>
              <p>
                <strong>{labels.supportingDocuments}</strong>
              </p>
              <ul>
                <li>{labels.referenceLetters}</li>
                <li>{labels.certificates}</li>
                <li>{labels.proofOfIncome}</li>
                <li>{labels.otherRelevantDocuments}</li>
              </ul>
            </div>
          }
          dir={lang === "ku" ? "rtl" : "ltr"}
        />
      </div>

      {fileError && (
        <p
          style={{
            color: "red",
            marginTop: "10px",
            textAlign: isRTL ? "right" : "left",
          }}
        >
          {fileError}
        </p>
      )}
    </div>
  );
};

export default UploadMultipleFiles;
