import { createSlice } from "@reduxjs/toolkit";
import {
  fetchOpenBookingSlots,
  uploadSingleIdDocument,
  deleteSingleIdDocument,
  uploadMultipleIdDocuments,
  deleteAllMultipleIdDocuments,
  createMarriageBooking,
} from "./thunks";
import {
  getPreviousMonth,
  getNextMonth,
  getDaysOpenForMonth,
  getFirstDayOfCurrentMonth,
} from "utils/dateUtils";

const initialState = {
  mosqueGlideId: "",
  userEmail: "",
  formData: {
    groomName: "",
    groomPhoneNumber: "",
    additionalInformation: "",
    idDocument: "",
    additionalDocuments: [],
    selectedTimeSlots: [],
  },
  currentMonth: getFirstDayOfCurrentMonth(),
  currentDaysOpenThisMonth: {},
  selectedDay: null,
  timeSlots: [],
  isSubmitting: false,
  submissionError: null,
  showThankYou: false,
  isLoadingSlots: false,
  uploadSingleFile: {
    singleFileError: null,
    singleFileName: "",
    singleFileUploadProgress: 0,
    isSingleFileUploaded: false,
    isDeletingSingleFile: false,
  },
  uploadMultipleFiles: {
    fileError: null,
    files: [],
    isDeletingAll: false,
  },
};

const marriageFormSlice = createSlice({
  name: "marriageForm",
  initialState,
  reducers: {
    setMosqueGlideIdAndUserEmail: (state, action) => {
      state.mosqueGlideId = action.payload.mosqueGlideId;
      state.userEmail = action.payload.userEmail;
    },
    setFormField: (state, action) => {
      const { field, value } = action.payload;
      if (field === "additionalDocuments") {
        state.formData.additionalDocuments = Array.isArray(value)
          ? value
          : value.split(",").map((item) => item.trim());
      } else {
        state.formData[field] = value;
      }
    },
    resetForm: (state) => {
      state.formData = {
        groomName: "",
        groomPhoneNumber: "",
        additionalInformation: "",
        idDocument: "",
        additionalDocuments: [],
        selectedTimeSlots: [],
      };
      state.selectedDay = null;
      state.timeSlots = [];
      state.uploadSingleFile = {
        singleFileError: null,
        singleFileName: "",
        singleFileUploadProgress: 0,
        isSingleFileUploaded: false,
        isDeletingSingleFile: false,
      };
      state.uploadMultipleFiles = {
        fileError: null,
        files: [],
        isDeletingAll: false,
      };
      state.currentDaysOpenThisMonth = {};
    },
    // Manage submission states
    setIsSubmitting: (state, action) => {
      state.isSubmitting = action.payload;
    },
    setSubmissionError: (state, action) => {
      state.submissionError = action.payload;
    },
    setShowThankYou: (state, action) => {
      state.showThankYou = action.payload;
    },
    // Single File Upload Reducers
    setSingleFileError: (state, action) => {
      state.uploadSingleFile.singleFileError = action.payload;
    },
    setSingleFileName: (state, action) => {
      state.uploadSingleFile.singleFileName = action.payload;
    },
    setSingleFileUploadProgress: (state, action) => {
      state.uploadSingleFile.singleFileUploadProgress = action.payload;
    },
    setIsSingleFileUploaded: (state, action) => {
      state.uploadSingleFile.isSingleFileUploaded = action.payload;
    },
    clearSingleFileUpload: (state) => {
      state.uploadSingleFile = {
        singleFileError: null,
        singleFileName: "",
        singleFileUploadProgress: 0,
        isSingleFileUploaded: false,
        isDeletingSingleFile: false,
      };
      state.formData.idDocument = "";
    },
    // Multiple Files Upload Reducers
    setMultipleFileError: (state, action) => {
      state.uploadMultipleFiles.fileError = action.payload;
    },
    addMultipleFile: (state, action) => {
      const { fileName } = action.payload;
      state.uploadMultipleFiles.files.push({
        fileName,
        fileUrl: "",
        uploadProgress: 0,
        isUploaded: false,
        isDeleting: false,
        error: null,
      });
    },
    updateMultipleFileProgress: (state, action) => {
      const { fileName, progress } = action.payload;
      const file = state.uploadMultipleFiles.files.find(
        (f) => f.fileName === fileName,
      );
      if (file) {
        file.uploadProgress = progress;
      }
    },
    markMultipleFileUploaded: (state, action) => {
      const { fileName, fileUrl } = action.payload;
      const file = state.uploadMultipleFiles.files.find(
        (f) => f.fileName === fileName,
      );
      if (file) {
        file.isUploaded = true;
        file.fileUrl = fileUrl;
        state.formData.additionalDocuments = state.uploadMultipleFiles.files
          .filter((f) => f.isUploaded)
          .map((f) => f.fileUrl);
      }
    },
    markMultipleFileError: (state, action) => {
      const { fileName, error } = action.payload;
      const file = state.uploadMultipleFiles.files.find(
        (f) => f.fileName === fileName,
      );
      if (file) {
        file.error = error;
      }
    },
    markAllMultipleFilesDeleting: (state) => {
      state.uploadMultipleFiles.isDeletingAll = true;
      state.uploadMultipleFiles.files.forEach((f) => {
        f.isDeleting = true;
      });
    },
    clearMultipleFilesUpload: (state) => {
      state.uploadMultipleFiles = {
        fileError: null,
        files: [],
        isDeletingAll: false,
      };
      state.formData.additionalDocuments = [];
    },
    setCurrentMonth: (state, action) => {
      state.currentMonth = action.payload;
    },
    goToPreviousMonth: (state) => {
      state.currentMonth = getPreviousMonth(state.currentMonth);
    },
    goToNextMonth: (state) => {
      state.currentMonth = getNextMonth(state.currentMonth);
    },
    setSelectedDay: (state, action) => {
      state.selectedDay = action.payload;
      const slots = state.currentDaysOpenThisMonth[action.payload] || [];
      const groupedSlots = new Map();
      slots.forEach((slot) => {
        const timeRangeKey = `${slot.start_datetime}-${slot.end_datetime}`;
        if (!groupedSlots.has(timeRangeKey)) {
          groupedSlots.set(timeRangeKey, {
            start: slot.start_datetime,
            end: slot.end_datetime,
            details: [slot],
          });
        } else {
          groupedSlots.get(timeRangeKey).details.push(slot);
        }
      });
      state.timeSlots = Array.from(groupedSlots.values());
      state.formData.selectedTimeSlots = [];
    },
    setSelectedTimeSlot: (state, action) => {
      state.formData.selectedTimeSlots = action.payload;
    },
    clearSelectedDay: (state) => {
      state.selectedDay = null;
      state.timeSlots = [];
      state.formData.selectedTimeSlots = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Create booking
      .addCase(createMarriageBooking.fulfilled, (state) => {
        // On success, form is reset and showThankYou is set in the thunk
        state.submissionError = null;
      })
      .addCase(createMarriageBooking.rejected, (state, action) => {
        state.submissionError = action.payload;
        state.showThankYou = false;
      })
      // Upload single
      .addCase(uploadSingleIdDocument.pending, (state) => {
        state.uploadSingleFile.singleFileError = null;
        state.uploadSingleFile.isSingleFileUploaded = false;
        state.uploadSingleFile.singleFileUploadProgress = 0;
      })
      .addCase(uploadSingleIdDocument.fulfilled, (state, action) => {
        state.uploadSingleFile.isSingleFileUploaded = true;
        state.formData.idDocument = action.payload.fileUrl;
      })
      .addCase(uploadSingleIdDocument.rejected, (state, action) => {
        state.uploadSingleFile.singleFileError = action.payload;
        state.uploadSingleFile.isSingleFileUploaded = false;
      })
      // Delete single
      .addCase(deleteSingleIdDocument.pending, (state) => {
        state.uploadSingleFile.isDeletingSingleFile = true;
      })
      .addCase(deleteSingleIdDocument.fulfilled, (state) => {
        state.uploadSingleFile = {
          singleFileError: null,
          singleFileName: "",
          singleFileUploadProgress: 0,
          isSingleFileUploaded: false,
          isDeletingSingleFile: false,
        };
        state.formData.idDocument = "";
      })
      .addCase(deleteSingleIdDocument.rejected, (state, action) => {
        state.uploadSingleFile.singleFileError = action.payload;
        state.uploadSingleFile.isDeletingSingleFile = false;
      })
      // Upload multiple
      .addCase(uploadMultipleIdDocuments.pending, (state) => {
        state.uploadMultipleFiles.fileError = null;
      })
      .addCase(uploadMultipleIdDocuments.rejected, (state, action) => {
        state.uploadMultipleFiles.fileError = action.payload;
      })
      // Delete multiple
      .addCase(deleteAllMultipleIdDocuments.rejected, (state, action) => {
        state.uploadMultipleFiles.fileError = action.payload;
        state.uploadMultipleFiles.isDeletingAll = false;
        state.uploadMultipleFiles.files.forEach((f) => {
          f.isDeleting = false;
        });
      })
      // Fetch booking slots
      .addCase(fetchOpenBookingSlots.pending, (state) => {
        state.isLoadingSlots = true;
      })
      .addCase(fetchOpenBookingSlots.fulfilled, (state, action) => {
        state.isLoadingSlots = false;
        const bookingSlots = action.payload.booking_slots || {};
        state.currentDaysOpenThisMonth = getDaysOpenForMonth(
          state.currentMonth,
          bookingSlots,
        );
      })
      .addCase(fetchOpenBookingSlots.rejected, (state, action) => {
        state.isLoadingSlots = false;
        state.submissionError = action.payload;
      });
  },
});

export const {
  setMosqueGlideIdAndUserEmail,
  setFormField,
  resetForm,
  setSingleFileError,
  setSingleFileName,
  setSingleFileUploadProgress,
  setIsSingleFileUploaded,
  clearSingleFileUpload,
  setMultipleFileError,
  clearMultipleFilesUpload,
  setCurrentMonth,
  goToPreviousMonth,
  goToNextMonth,
  setSelectedDay,
  setSelectedTimeSlot,
  clearSelectedDay,
  addMultipleFile,
  updateMultipleFileProgress,
  markMultipleFileUploaded,
  markMultipleFileError,
  markAllMultipleFilesDeleting,
  setIsSubmitting,
  setSubmissionError,
  setShowThankYou,
} = marriageFormSlice.actions;

export default marriageFormSlice.reducer;
