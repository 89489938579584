import React from "react";
import { Route } from "react-router-dom";
import AdminLayout from "layouts/AdminLayout";
import Home from "pages/dashboard/admin/Home";
import AskImaam from "pages/dashboard/admin/AskImaam";
import Events from "pages/dashboard/admin/Events";
import ManageMosque from "pages/dashboard/admin/ManageMosque";
import Payments from "pages/dashboard/admin/Payments";

const AdminRoutes = (
  <Route key="home" path="/dashboard/admin" element={<AdminLayout />}>
    <Route index element={<Home />} />
    <Route path="home" element={<Home />} />
    <Route path="ask-imaam" element={<AskImaam />} />
    <Route path="events" element={<Events />} />
    <Route path="manage-mosque" element={<ManageMosque />} />
    <Route path="payments" element={<Payments />} />
  </Route>
);

export default AdminRoutes;
