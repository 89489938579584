import React, { useState, useEffect } from "react";
import Header from "./Header";
import Hero from "./Hero";
import Body from "./Body";
import Footer from "./Footer";
import landscapeImage from "assets/landing_background_landscape.png";
import portraitImage from "assets/landing_background_portrait.png";

function Landing() {
  const [, setIsMobile] = useState(window.innerWidth <= 640);
  const [backgroundImage, setBackgroundImage] = useState(
    window.innerWidth <= 640 ? portraitImage : landscapeImage,
  );

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 640;
      setIsMobile(mobile);
      setBackgroundImage(mobile ? portraitImage : landscapeImage);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initialize on mount

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      {/* Background Section */}
      <div
        className="flex-grow relative"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "contain", // Ensures the image is shown in full
          backgroundPosition: "top", // Aligns the top of the image with the top of the container
          backgroundRepeat: "no-repeat",
          height: "auto", // Adjust height based on screen size
        }}
      >
        <div className="absolute inset-0 bg-black opacity-20"></div>

        {/* Content */}
        <div className="relative z-10">
          <Header />
          <Hero />
          <Body />
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Landing;
