import React, { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Button } from "components/shadcn/Button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "components/shadcn/Card";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "components/shadcn/Select";

import adminAPI from "api/adminAPI";
import { getDaysInMonth, getFormattedMonth } from "utils/dateUtils";
import { DAYS_OF_WEEK } from "constants/formsConstants";

/**
 * Returns a string "YYYY-MM-DD" from a Date, zero-padded as needed.
 */
function getDateKey(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

/**
 * Displays bookings on a monthly calendar, with:
 * - a status filter
 * - clickable days that show only the booking slots for that specific day
 * - additional booking "form_data" displayed elegantly
 */
function Bookings() {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [bookings, setBookings] = useState([]);
  const [statusFilter, setStatusFilter] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        setIsLoading(true);
        const response = await adminAPI.getAllBookings();
        if (response && response.bookings) {
          setBookings(response.bookings);
        }
      } catch (error) {
        console.error("Error fetching bookings:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchBookings();
  }, []);

  const goToPreviousMonth = () => {
    setCurrentMonth((prev) => {
      const year = prev.getFullYear();
      const month = prev.getMonth();
      setSelectedDay(null);
      return new Date(year, month - 1, 1);
    });
  };

  const goToNextMonth = () => {
    setCurrentMonth((prev) => {
      const year = prev.getFullYear();
      const month = prev.getMonth();
      setSelectedDay(null);
      return new Date(year, month + 1, 1);
    });
  };

  const days = getDaysInMonth(currentMonth);
  const monthString = getFormattedMonth(currentMonth);
  const uniqueStatuses = Array.from(new Set(bookings.map((b) => b.status)));

  const filteredBookings = bookings.filter((booking) => {
    if (statusFilter === "all") return true;
    return booking.status === statusFilter;
  });

  const bookingsByDay = {};

  filteredBookings.forEach((booking) => {
    if (Array.isArray(booking.booking_slots)) {
      booking.booking_slots.forEach((slot) => {
        const slotDate = new Date(slot.start_datetime);
        if (!isNaN(slotDate)) {
          const dayKey = getDateKey(slotDate);
          if (!bookingsByDay[dayKey]) {
            bookingsByDay[dayKey] = [];
          }
          if (!bookingsByDay[dayKey].includes(booking.booking_id)) {
            bookingsByDay[dayKey].push(booking.booking_id);
          }
        }
      });
    }
  });

  const handleDayClick = (dayKey) => {
    if (bookingsByDay[dayKey]?.length > 0) {
      setSelectedDay(dayKey);
    }
  };

  const renderDayCell = (day, index) => {
    if (!day) {
      return (
        <div
          key={`placeholder-${index}`}
          className="flex items-center justify-center w-10 h-10 rounded-md"
        />
      );
    }

    const dayDate = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      day,
    );
    const dayKey = getDateKey(dayDate);
    const hasBookings = (bookingsByDay[dayKey] || []).length > 0;

    return (
      <div key={dayKey} className="p-1">
        <div
          onClick={() => handleDayClick(dayKey)}
          className={`flex items-center justify-center w-10 h-10 rounded-md cursor-pointer ${
            hasBookings
              ? "bg-green-300 text-green-900 hover:bg-green-400"
              : "bg-secondary text-secondary-foreground"
          }`}
        >
          {day}
        </div>
      </div>
    );
  };

  const selectedDayBookingIds = selectedDay
    ? bookingsByDay[selectedDay] || []
    : [];
  const selectedDayBookings = bookings.filter((b) =>
    selectedDayBookingIds.includes(b.booking_id),
  );

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <Button variant="ghost" onClick={goToPreviousMonth}>
          <ChevronLeft className="w-5 h-5 text-gray-500 hover:text-gray-700" />
        </Button>
        <h2 className="text-lg font-medium">{monthString}</h2>
        <Button variant="ghost" onClick={goToNextMonth}>
          <ChevronRight className="w-5 h-5 text-gray-500 hover:text-gray-700" />
        </Button>
      </div>

      <div className="flex items-center space-x-4">
        <span className="font-medium">Filter by Status:</span>
        <Select
          value={statusFilter}
          onValueChange={(value) => {
            setSelectedDay(null);
            setStatusFilter(value);
          }}
        >
          <SelectTrigger className="w-40">
            <SelectValue placeholder="Select status" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All</SelectItem>
            {uniqueStatuses.map((st) => (
              <SelectItem key={st} value={st}>
                {st}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="flex flex-col lg:flex-row lg:space-x-6">
        {/* Calendar Section */}
        <div className="w-full lg:w-1/3">
          <div>
            <div className="grid grid-cols-7 gap-1 justify-items-center mb-2">
              {DAYS_OF_WEEK.map((dayLabel) => (
                <div
                  key={dayLabel}
                  className="text-center font-medium text-gray-600"
                >
                  {dayLabel}
                </div>
              ))}
            </div>

            <div className="grid grid-cols-7 gap-1 justify-items-center">
              {isLoading
                ? days.map((_, idx) => (
                    <div
                      key={idx}
                      className="flex items-center justify-center w-10 h-10 rounded-md bg-gray-200 animate-pulse"
                    />
                  ))
                : days.map((day, idx) => renderDayCell(day, idx))}
            </div>
          </div>
        </div>

        {/* Booking Details Section */}
        {selectedDay && !isLoading && (
          <div className="w-full lg:w-2/3">
            {selectedDayBookings.length > 0 ? (
              <div className="space-y-4">
                <h3 className="text-md font-semibold">
                  Bookings for {selectedDay}:
                </h3>
                {selectedDayBookings.map((booking) => {
                  const slotsForThisDay = booking.booking_slots.filter(
                    (slot) => {
                      const slotDateKey = getDateKey(
                        new Date(slot.start_datetime),
                      );
                      return slotDateKey === selectedDay;
                    },
                  );

                  return (
                    <Card key={booking.booking_id}>
                      <CardHeader>
                        <CardTitle className="text-sm">
                          Booking ID: {booking.booking_id}
                        </CardTitle>
                        <CardDescription>
                          Status: {booking.status}
                        </CardDescription>
                      </CardHeader>
                      <CardContent className="space-y-2 text-sm">
                        <div>
                          <p className="font-medium">
                            User ID: {booking.user_id}
                          </p>
                          <p className="font-medium">
                            Mosque ID: {booking.mosque_id}
                          </p>
                        </div>
                        {slotsForThisDay.length > 0 && (
                          <div className="space-y-1">
                            <p className="font-medium">Slots:</p>
                            {slotsForThisDay.map((slot) => (
                              <div
                                key={slot.booking_slot_id}
                                className="bg-muted rounded px-2 py-1"
                              >
                                <p>Slot ID: {slot.booking_slot_id}</p>
                                <p>Start: {slot.start_datetime}</p>
                                <p>End: {slot.end_datetime}</p>
                              </div>
                            ))}
                          </div>
                        )}
                        {booking.form_data && (
                          <div className="space-y-2 mt-2">
                            <p className="font-medium">Form Data:</p>
                            <div className="bg-muted rounded-lg p-2 text-muted-foreground space-y-1">
                              {Object.entries(booking.form_data).map(
                                ([key, value]) => (
                                  <div key={key}>
                                    <span className="font-semibold mr-1">
                                      {key}:
                                    </span>
                                    <span>
                                      {typeof value === "object"
                                        ? JSON.stringify(value)
                                        : String(value)}
                                    </span>
                                  </div>
                                ),
                              )}
                            </div>
                          </div>
                        )}
                      </CardContent>
                    </Card>
                  );
                })}
              </div>
            ) : (
              <div className="mt-2">
                <h3 className="text-md font-semibold">
                  No bookings on {selectedDay}
                </h3>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Bookings;
