import React from "react";
import { Route } from "react-router-dom";
import Landing from "pages/landing/Landing";
import Auth from "pages/auth/Auth";
import PrivacyPolicy from "pages/policies/PrivacyPolicy";
import TermsOfService from "pages/policies/TermsOfService";
import MarriageForm from "forms/marriage-form/MarriageForm";
import SpacesForm from "forms/spaces-form/SpacesForm";

const PublicRoutes = [
  <Route key="landing" path="/" element={<Landing />} />,
  <Route key="auth" path="/auth" element={<Auth />} />,
  <Route
    key="privacy-policy"
    path="/privacy-policy"
    element={<PrivacyPolicy />}
  />,
  <Route
    key="terms-of-service"
    path="/terms-of-service"
    element={<TermsOfService />}
  />,
  <Route
    key="marriage-form"
    path="/marriage-form"
    element={<MarriageForm />}
  />,
  <Route key="spaces-form" path="/spaces-form" element={<SpacesForm />} />,
];

export default PublicRoutes;
