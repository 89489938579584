import React from "react";
import { getFormattedDateRange } from "utils/dateUtils";
import { SPACES_OPTIONS } from "constants/formsConstants";
import PaymentTypeAndAmount from "./PaymentTypeAndAmount";
import TermsAndConditions from "./TermsAndConditions";

function Stage3Overview({
  formData,
  spaceIds,
  errors,
  labels,
  handlePaymentTypeChange,
  handleAgreedChange,
}) {
  const selectedSpaces =
    SPACES_OPTIONS.filter((s) => spaceIds.includes(s.id))
      .map((s) => s.name["en"])
      .join(", ") || "None";

  const selectedTimeslotDisplay =
    formData.selectedTimeSlots && formData.selectedTimeSlots.length > 0
      ? getFormattedDateRange(
          formData.selectedTimeSlots[0].start_datetime,
          formData.selectedTimeSlots[0].end_datetime,
        )
      : "Not selected";

  return (
    <>
      <h3 className="text-xl font-semibold mb-4">{labels.bookingOverview}</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div className="space-y-2">
          <p>
            <span className="font-semibold">{labels.overviewName}</span>{" "}
            {formData.name}
          </p>
          <p>
            <span className="font-semibold">{labels.overviewPhone}</span>{" "}
            {formData.phoneNumber}
          </p>
          <p>
            <span className="font-semibold">{labels.overviewPurpose}</span>{" "}
            {formData.purposeOfHire}
          </p>
          <p>
            <span className="font-semibold">{labels.overviewGuests}</span>{" "}
            {formData.numberOfPeople}
          </p>
        </div>

        <div className="space-y-2">
          <p>
            <span className="font-semibold">
              {labels.overviewSpacesSelected}
            </span>{" "}
            {selectedSpaces}
          </p>
          <p>
            <span className="font-semibold">
              {labels.overviewAdditionalInfo}
            </span>{" "}
            {formData.additionalInformation || "None"}
          </p>
          <p>
            <span className="font-semibold">{labels.overviewDatetime}</span>{" "}
            {selectedTimeslotDisplay}
          </p>
        </div>
      </div>

      <PaymentTypeAndAmount
        paymentType={formData.paymentType}
        paymentAmount={formData.paymentAmount}
        onPaymentTypeChange={handlePaymentTypeChange}
        error={errors.paymentType}
        labels={labels}
      />

      <TermsAndConditions
        agreedToTerms={formData.agreedToTerms}
        onAgreedChange={handleAgreedChange}
        error={errors.agreedToTerms}
        labels={labels}
      />
    </>
  );
}

export default Stage3Overview;
