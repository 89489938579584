// MONTH NAVIGATION UTILITIES
export const getPreviousMonth = (currentMonth) => {
  return new Date(
    new Date(currentMonth).setMonth(new Date(currentMonth).getMonth() - 1),
  )
    .toISOString()
    .slice(0, 10);
};

export const getNextMonth = (currentMonth) => {
  return new Date(
    new Date(currentMonth).setMonth(new Date(currentMonth).getMonth() + 1),
  )
    .toISOString()
    .slice(0, 10);
};

// DATE FORMATTING UTILITIES
export const getFormattedDay = (currentMonth, day) => {
  // Returns a date string in "YYYY-MM-DD" format for a specific day of a month
  return `${new Date(currentMonth).toISOString().slice(0, 7)}-${String(day).padStart(2, "0")}`;
};

export const getFormattedMonth = (currentMonth) => {
  // Returns the full month and year, e.g., "December 2024"
  const date = new Date(currentMonth);
  return date.toLocaleString("default", {
    month: "long",
    year: "numeric",
  });
};

export const getFormattedTimeRange = (startDatetime, endDatetime) => {
  // Formats a start and end datetime into "HH:MM AM/PM - HH:MM AM/PM"
  return `${new Date(startDatetime).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  })} - ${new Date(endDatetime).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  })}`;
};

export const getFormattedDateRange = (startDate, endDate) =>
  // Formats a start and end date into "December 11, 2024 from 10:00 - 18:00"
  `${new Date(startDate).toLocaleString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
  })} from ${new Date(startDate).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  })} - ${new Date(endDate).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  })}`;

// MONTH STRUCTURE UTILITIES
export const getFirstDayOfCurrentMonth = () => {
  // Returns the first day of the current month in "YYYY-MM-DD" format
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), 1)
    .toISOString()
    .slice(0, 10);
};

export const getDaysInMonth = (currentMonth) => {
  // Returns an array of days in a month, starting with nulls for offset days
  const year = new Date(currentMonth).getUTCFullYear();
  const month = new Date(currentMonth).getUTCMonth();

  const firstDayOfMonth = new Date(Date.UTC(year, month, 1)).getUTCDay();
  const daysInMonth = new Date(Date.UTC(year, month + 1, 0)).getUTCDate();
  const days = [];

  for (let i = 0; i < firstDayOfMonth; i++) {
    days.push(null);
  }

  for (let i = 1; i <= daysInMonth; i++) {
    days.push(i);
  }

  return days;
};

// BOOKING UTILITIES
export const getDaysOpenForMonth = (currentMonth, bookingSlots) => {
  // Filters and returns booking slots for the given month
  const currentMonthYear = new Date(currentMonth).toISOString().slice(0, 7);
  return Object.keys(bookingSlots).reduce((acc, date) => {
    if (date.startsWith(currentMonthYear)) {
      acc[date] = bookingSlots[date];
    }
    return acc;
  }, {});
};
