import React from "react";
import { Route } from "react-router-dom";
import AttendeeLayout from "layouts/AttendeeLayout";
import Home from "pages/dashboard/attendee/Home";
import Events from "pages/dashboard/attendee/Events";
import Subscription from "pages/dashboard/attendee/Subscription";
import News from "pages/dashboard/attendee/news/News";
import Settings from "pages/dashboard/attendee/Settings";

// Import service components
import Services from "pages/dashboard/attendee/services/Services";
import Funeral from "pages/dashboard/attendee/services/Funeral";
import QuranicStudies from "pages/dashboard/attendee/services/QuranicStudies";
import IslamicSchool from "pages/dashboard/attendee/services/IslamicSchool";
import IslamicCounselling from "pages/dashboard/attendee/services/IslamicCounselling";
import Library from "pages/dashboard/attendee/services/Library";
import Health from "pages/dashboard/attendee/services/Health";
import Language from "pages/dashboard/attendee/services/Language";
import AskImaam from "pages/dashboard/attendee/services/AskImaam";

const AttendeeRoutes = [
  <Route key="attendee" path="/dashboard/attendee" element={<AttendeeLayout />}>
    {/* Nested routes */}
    <Route index element={<Home />} />
    <Route path="home" element={<Home />} />
    <Route path="news" element={<News />} />
    <Route path="events" element={<Events />} />
    <Route path="subscription" element={<Subscription />} />
    <Route path="settings" element={<Settings />} />

    {/* Services Routes */}
    <Route path="services">
      <Route index element={<Services />} />
      <Route path="funeral" element={<Funeral />} />
      <Route path="quranic-studies" element={<QuranicStudies />} />
      <Route path="islamic-school" element={<IslamicSchool />} />
      <Route path="counselling" element={<IslamicCounselling />} />
      <Route path="library" element={<Library />} />
      <Route path="health" element={<Health />} />
      <Route path="language" element={<Language />} />
      <Route path="ask-imaam" element={<AskImaam />} />
    </Route>
  </Route>,
];

export default AttendeeRoutes;
