import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SPACES_RESOURCE_ID } from "constants/formsConstants";
import { setClientSecret, setPaymentId } from "./spacesFormSlice";
import { apiUrl } from "config";

export const fetchOpenBookingSlots = createAsyncThunk(
  "spacesForm/fetchOpenBookingSlots",
  async ({ startDate }, { getState, rejectWithValue }) => {
    const state = getState().spacesForm;
    const spaceIds = state.spaceIds;

    try {
      const response = await axios.post(
        `${apiUrl}/booking_slots/get_open_booking_slots`,
        {
          resource_id: SPACES_RESOURCE_ID,
          space_ids: spaceIds,
          start_date: startDate,
        },
      );
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || "Failed to fetch booking slots";
      return rejectWithValue(errorMessage);
    }
  },
);

export const createPaymentIntent = createAsyncThunk(
  "spacesForm/createPaymentIntent",
  async (_, { getState, rejectWithValue, dispatch }) => {
    const state = getState().spacesForm;
    const { formData, spaceIds, mosqueGlideId, userEmail } = state;

    try {
      const response = await axios.post(`${apiUrl}/payments/payment-intent`, {
        user_email: userEmail,
        mosque_glide_id: mosqueGlideId,
        payment_type: formData.paymentType,
        payment_amount: formData.paymentAmount,
        form_data: {
          booking_slot_ids: formData.selectedTimeSlots.map(
            (slot) => slot.booking_slot_id,
          ),
          space_ids: spaceIds,
          name: formData.name,
          phone_number: formData.phoneNumber,
          purpose_of_hire: formData.purposeOfHire,
          number_of_people: formData.numberOfPeople,
          agreed_to_terms: formData.agreedToTerms,
          additional_information: formData.additionalInformation,
        },
      });

      const data = response.data;

      dispatch(setClientSecret(data.client_secret));
      dispatch(setPaymentId(data.payment_id));
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || "Failed to create payment intent";
      return rejectWithValue(errorMessage);
    }
  },
);

export const createSpacesBooking = createAsyncThunk(
  "spacesForm/createSpacesBooking",
  async (_, { getState, rejectWithValue }) => {
    const state = getState().spacesForm;
    const { formData, spaceIds, mosqueGlideId, userEmail } = state;

    const payload = {
      user_email: userEmail,
      mosque_glide_id: mosqueGlideId,
      booking_slot_ids: formData.selectedTimeSlots.map(
        (slot) => slot.booking_slot_id,
      ),
      name: formData.name,
      phone_number: formData.phoneNumber,
      purpose_of_hire: formData.purposeOfHire,
      number_of_people: formData.numberOfPeople,
      space_ids: spaceIds,
      payment_type:
        formData.paymentType === "full"
          ? "full_payment"
          : formData.paymentType === "deposit"
            ? "deposit_payment"
            : "cash_payment",
      payment_amount: formData.paymentAmount,
      agreed_to_terms: formData.agreedToTerms,
      additional_information: formData.additionalInformation,
    };

    try {
      const response = await axios.post(`${apiUrl}/bookings/spaces`, payload);
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || "Failed to create booking";
      return rejectWithValue(errorMessage);
    }
  },
);
