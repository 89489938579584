import React, { useState, useEffect } from "react";
import adminAPI from "api/adminAPI";

// shadcn/ui components
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "components/shadcn/Card";
import { Skeleton } from "components/shadcn/Skeleton";
import { Alert, AlertDescription } from "components/shadcn/Alert";
import { Button } from "components/shadcn/Button";
import { Input } from "components/shadcn/Input";

import BookingSlotList from "./BookingSlotList";

const ResourceList = ({ mosqueId, mosqueGlideId }) => {
  const [resources, setResources] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  // For editing a resource
  const [editResourceId, setEditResourceId] = useState(null);
  const [editResourceData, setEditResourceData] = useState({
    name: "",
    description: "",
  });

  // For editing a space
  const [editSpaceId, setEditSpaceId] = useState(null);
  const [editSpaceData, setEditSpaceData] = useState({
    name: "",
    description: "",
  });

  useEffect(() => {
    const fetchResources = async () => {
      try {
        setIsLoading(true);
        const resourcesData = await adminAPI.getResources(
          mosqueId,
          mosqueGlideId,
        );
        setResources(resourcesData);
      } catch (err) {
        console.error("Error fetching resources:", err);
        setError("Failed to fetch resources.");
      } finally {
        setIsLoading(false);
      }
    };

    if (mosqueId && mosqueGlideId) {
      fetchResources();
    }
  }, [mosqueId, mosqueGlideId]);

  // -----------------
  // Handle resource editing
  // -----------------
  const handleEditResource = (resource) => {
    setEditResourceId(resource.resource_id);
    setEditResourceData({
      name: resource.name,
      description: resource.description,
    });
  };

  const handleSaveResource = async (resourceId) => {
    try {
      setIsLoading(true);
      await adminAPI.updateResource(
        resourceId,
        editResourceData.name.trim(),
        editResourceData.description.trim(),
      );
      setEditResourceId(null);
      setEditResourceData({ name: "", description: "" });
      // Refetch
      const updatedResources = await adminAPI.getResources(
        mosqueId,
        mosqueGlideId,
      );
      setResources(updatedResources);
    } catch (err) {
      console.error("Error updating resource:", err);
      setError("Failed to update resource.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelResourceEdit = () => {
    setEditResourceId(null);
    setEditResourceData({ name: "", description: "" });
  };

  // -----------------
  // Handle space editing
  // -----------------
  const handleEditSpace = (space) => {
    setEditSpaceId(space.space_id);
    setEditSpaceData({
      name: space.name,
      description: space.description,
    });
  };

  const handleSaveSpace = async (spaceId) => {
    try {
      setIsLoading(true);
      await adminAPI.updateSpace(
        spaceId,
        editSpaceData.name.trim(),
        editSpaceData.description.trim(),
      );
      setEditSpaceId(null);
      setEditSpaceData({ name: "", description: "" });
      // Refetch
      const updatedResources = await adminAPI.getResources(
        mosqueId,
        mosqueGlideId,
      );
      setResources(updatedResources);
    } catch (err) {
      console.error("Error updating space:", err);
      setError("Failed to update space.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelSpaceEdit = () => {
    setEditSpaceId(null);
    setEditSpaceData({ name: "", description: "" });
  };

  if (isLoading) {
    return (
      <div className="space-y-4">
        <Skeleton className="h-10 w-full" />
        <Skeleton className="h-64 w-full" />
        <Skeleton className="h-64 w-full" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive" className="mb-4">
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="space-y-4">
      {resources.map((resource) => {
        const isEditingResource = editResourceId === resource.resource_id;

        return (
          <Card key={resource.resource_id} className="">
            <CardHeader>
              {isEditingResource ? (
                <>
                  <Input
                    value={editResourceData.name}
                    onChange={(e) =>
                      setEditResourceData((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                    className="mb-2"
                  />
                  <Input
                    value={editResourceData.description}
                    onChange={(e) =>
                      setEditResourceData((prev) => ({
                        ...prev,
                        description: e.target.value,
                      }))
                    }
                    className="mb-2"
                  />
                  <div>
                    <Button
                      size="sm"
                      className="mr-2"
                      onClick={() => handleSaveResource(resource.resource_id)}
                    >
                      Save
                    </Button>
                    <Button
                      size="sm"
                      variant="secondary"
                      onClick={handleCancelResourceEdit}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <CardTitle className="text-lg font-semibold">
                    {resource.name}
                  </CardTitle>
                  <div className="flex items-center mt-2">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => handleEditResource(resource)}
                    >
                      Edit Resource
                    </Button>
                  </div>
                </>
              )}
            </CardHeader>
            <CardContent>
              {!isEditingResource && (
                <p className="text-sm mb-2">{resource.description}</p>
              )}

              {/* If this is the "spaces" resource, show spaces */}
              {resource.resource_id ===
              "33333333-3333-3333-3333-333333333333" ? (
                resource.spaces && resource.spaces.length > 0 ? (
                  <div className="mt-2">
                    {resource.spaces.map((space) => {
                      const isEditingCurrentSpace =
                        editSpaceId === space.space_id;
                      return (
                        <div
                          key={space.space_id}
                          className="border rounded p-2 mb-2"
                        >
                          {isEditingCurrentSpace ? (
                            <>
                              <Input
                                value={editSpaceData.name}
                                onChange={(e) =>
                                  setEditSpaceData((prev) => ({
                                    ...prev,
                                    name: e.target.value,
                                  }))
                                }
                                className="mb-2"
                              />
                              <Input
                                value={editSpaceData.description}
                                onChange={(e) =>
                                  setEditSpaceData((prev) => ({
                                    ...prev,
                                    description: e.target.value,
                                  }))
                                }
                                className="mb-2"
                              />
                              <div>
                                <Button
                                  size="sm"
                                  className="mr-2"
                                  onClick={() =>
                                    handleSaveSpace(space.space_id)
                                  }
                                >
                                  Save
                                </Button>
                                <Button
                                  size="sm"
                                  variant="secondary"
                                  onClick={handleCancelSpaceEdit}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="flex items-center justify-between">
                                <p className="font-medium text-sm">
                                  {space.name}
                                </p>
                                <Button
                                  variant="outline"
                                  size="sm"
                                  onClick={() => handleEditSpace(space)}
                                >
                                  Edit Space
                                </Button>
                              </div>
                              <p className="text-xs text-muted-foreground mb-2">
                                {space.description}
                              </p>

                              {/* Booking Slots for this space */}
                              <BookingSlotList
                                resource={{
                                  resource_id: resource.resource_id,
                                  space_id: space.space_id,
                                }}
                              />
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <p className="text-muted-foreground italic text-sm">
                    No spaces available for this resource.
                  </p>
                )
              ) : (
                /* For normal resources, just show booking slots */
                <BookingSlotList
                  resource={{ resource_id: resource.resource_id }}
                />
              )}
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
};

export default ResourceList;
