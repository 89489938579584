import React from "react";
import { Elements } from "@stripe/react-stripe-js";

import PaymentForm from "./PaymentForm";
import { loadStripe } from "@stripe/stripe-js";
import { stripePublicKey } from "config";

const stripePromise = loadStripe(stripePublicKey);

function Stage4Payment({ clientSecret, onSuccess }) {
  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <PaymentForm onSuccess={onSuccess} />
    </Elements>
  );
}

export default Stage4Payment;
