import React from "react";
import { Checkbox } from "components/shadcn/Checkbox";
import { SPACES_OPTIONS } from "constants/formsConstants";
import Calendar from "./Calendar";
import InfoTooltip from "../InfoTooltip";

function Stage2SpacesAndCalendar({
  errors,
  spaceIds,
  lang,
  isRTL,
  handleSpaceChange,
  handleTimeSlotChange,
}) {
  return (
    <>
      <div className="mb-4" dir={isRTL ? "rtl" : "ltr"}>
        {SPACES_OPTIONS.map((space) => (
          <div key={space.id} className="flex items-center mb-2">
            <div className="px-2">
              <Checkbox
                id={space.id}
                checked={spaceIds.includes(space.id)}
                onCheckedChange={() => handleSpaceChange(space.id)}
              />
            </div>
            <label htmlFor={space.id} className="flex items-center">
              <span>
                {isRTL
                  ? ` ${space.name[lang]} (${space.cost.toFixed(2)}£)`
                  : `${space.name[lang]} (£${space.cost.toFixed(2)})`}
              </span>
              <InfoTooltip content={space.tooltip[lang]} />
            </label>
          </div>
        ))}
        {errors.spaceIds && (
          <div className="text-red-500 text-sm">{errors.spaceIds}</div>
        )}
      </div>

      <div className="mb-4">
        <Calendar onTimeSlotChange={handleTimeSlotChange} />
        {errors.selectedTimeSlots && (
          <div className="text-red-500 text-sm">{errors.selectedTimeSlots}</div>
        )}
      </div>
    </>
  );
}

export default Stage2SpacesAndCalendar;
