import React from "react";
import { SPACES_FORM_LABELS } from "constants/formsConstants";

function ThankYou({ lang = "en" }) {
  const labels = SPACES_FORM_LABELS[lang];

  return (
    <div className="max-w-md mx-auto p-4 text-center">
      <h2 className="text-2xl font-bold mb-4">{labels.thankYouTitle}</h2>
      <p>{labels.thankYouMessage}</p>
    </div>
  );
}

export default ThankYou;
