import React, { useState, useEffect } from "react";
import adminAPI from "api/adminAPI";
import { Button } from "components/shadcn/Button";
import { Input } from "components/shadcn/Input";
import { Alert, AlertDescription } from "components/shadcn/Alert";
import { Skeleton } from "components/shadcn/Skeleton";
import { ChevronLeft, ChevronRight, Pen, Trash2 } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/shadcn/Select";

// shadcn Dialog components
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogTrigger,
  DialogFooter,
} from "components/shadcn/Dialog";

// Days of the week header
const DAYS_OF_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

/**
 * Returns an array of days for a given year/month, including leading nulls
 * for offset at the beginning of the month. For example:
 * [null, null, 1, 2, 3, 4, ...31]
 */
function getDaysInMonth(year, monthIndex) {
  const date = new Date(year, monthIndex, 1);
  const days = [];

  // day of week for the 1st of the month (0=Sunday,...,6=Saturday)
  const firstDayOfWeek = date.getDay();
  for (let i = 0; i < firstDayOfWeek; i++) {
    days.push(null);
  }

  // push real days
  while (date.getMonth() === monthIndex) {
    days.push(date.getDate());
    date.setDate(date.getDate() + 1);
  }
  return days;
}

/** Convert a Date object to "YYYY-MM-DD" string */
function toYMD(dateObj) {
  const yyyy = dateObj.getFullYear();
  const mm = String(dateObj.getMonth() + 1).padStart(2, "0");
  const dd = String(dateObj.getDate()).padStart(2, "0");
  return `${yyyy}-${mm}-${dd}`;
}

/** Combine date "2025-01-01" and time "11:11" => "2025-01-01 11:11:00" */
function combineDateTime(dateStr, timeStr) {
  // If user typed only HH:MM, append :00
  let timeWithSeconds = timeStr;
  if (/^\d{2}:\d{2}$/.test(timeStr)) {
    timeWithSeconds += ":00";
  }
  return `${dateStr} ${timeWithSeconds}`;
}

/** Split "2024-12-10 09:00:00" => { datePart: "2024-12-10", timePart: "09:00:00" } */
function splitDateTime(dateTimeStr) {
  const [datePart, timePart] = dateTimeStr.split(" ");
  return { datePart, timePart };
}

/** Return monthName + space + year, e.g. "December 2024" */
function getFormattedMonth(dateObj) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return `${monthNames[dateObj.getMonth()]} ${dateObj.getFullYear()}`;
}

/**
 * Helper function to see if two time intervals overlap.
 * Accepts "startDatetime" and "endDatetime" strings in "YYYY-MM-DD HH:MM:SS"
 */
function doIntervalsOverlap(aStart, aEnd, bStart, bEnd) {
  // Convert strings to JS Date objects
  const aStartDate = new Date(aStart.replace(" ", "T"));
  const aEndDate = new Date(aEnd.replace(" ", "T"));
  const bStartDate = new Date(bStart.replace(" ", "T"));
  const bEndDate = new Date(bEnd.replace(" ", "T"));

  // They overlap if:
  // (A starts before B ends) AND (B starts before A ends)
  return aStartDate < bEndDate && bStartDate < aEndDate;
}

function BookingSlotList({ resource }) {
  // -----------------------------------------
  // STATE
  // -----------------------------------------
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // For existing booking slots
  const [bookingSlots, setBookingSlots] = useState({});

  // For editing an existing slot
  const [editSlotId, setEditSlotId] = useState(null);
  const [editSlotData, setEditSlotData] = useState({
    start_datetime: "",
    end_datetime: "",
    status: "open",
  });

  // -----------------------------------------
  // Dialog (popup) State
  // -----------------------------------------
  const [openDialog, setOpenDialog] = useState(false);

  // For creating new booking slots across multiple days (in the popup)
  const [newSlot, setNewSlot] = useState({
    start_time: "",
    end_time: "",
    status: "open",
  });
  const [selectedDaysForCreation, setSelectedDaysForCreation] = useState([]);

  // -----------------------------------------
  // CALENDAR: a single month + day selection for viewing
  // -----------------------------------------
  const [currentMonthDate, setCurrentMonthDate] = useState(new Date()); // today's month
  const [selectedDay, setSelectedDay] = useState(null);

  // Derive year/monthIndex from currentMonthDate
  const year = currentMonthDate.getFullYear();
  const monthIndex = currentMonthDate.getMonth();

  // -----------------------------------------
  // FETCH booking slots
  // -----------------------------------------
  async function fetchBookingSlots() {
    try {
      setIsLoading(true);
      setError("");

      const today = new Date();
      const endDate = new Date(2025, 11, 31); // Dec 31, 2025

      const payload = {
        resource_id: resource.resource_id,
        start_date: toYMD(today),
        end_date: toYMD(endDate),
      };

      // If "spaces" resource, add space_ids
      if (
        resource.resource_id === "33333333-3333-3333-3333-333333333333" &&
        resource.space_id
      ) {
        payload.space_ids = [resource.space_id];
      }

      const resp = await adminAPI.getOpenBookingSlots(payload);
      if (resp && resp.booking_slots) {
        setBookingSlots(resp.booking_slots);
      } else {
        setBookingSlots({});
      }
    } catch (err) {
      console.error("Error fetching booking slots:", err);
      setError("Failed to fetch booking slots.");
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (resource && resource.resource_id) {
      fetchBookingSlots();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource]);

  // -----------------------------------------
  // Navigate Month inside the Popup
  // -----------------------------------------
  const handlePrevMonth = () => {
    const newDate = new Date(currentMonthDate);
    newDate.setMonth(newDate.getMonth() - 1);
    setCurrentMonthDate(newDate);
    setSelectedDay(null);
  };

  const handleNextMonth = () => {
    const newDate = new Date(currentMonthDate);
    newDate.setMonth(newDate.getMonth() + 1);
    setCurrentMonthDate(newDate);
    setSelectedDay(null);
  };

  // -----------------------------------------
  // View existing slots for a day
  // -----------------------------------------
  function handleDayClick(day) {
    if (!day) return;
    const mm = String(monthIndex + 1).padStart(2, "0");
    const dd = String(day).padStart(2, "0");
    const dayStr = `${year}-${mm}-${dd}`;

    // If dayStr in bookingSlots, set it
    if (bookingSlots[dayStr]) {
      setSelectedDay(dayStr);
    } else {
      setSelectedDay(null);
    }
  }

  // -----------------------------------------
  // Check if day is "clashing" with newSlot times
  // -----------------------------------------
  function dayHasClash(dayStr) {
    if (!bookingSlots[dayStr]) return false;

    // Combine date/time
    let st = newSlot.start_time.trim();
    let et = newSlot.end_time.trim();
    if (/^\d{2}:\d{2}$/.test(st)) st += ":00";
    if (/^\d{2}:\d{2}$/.test(et)) et += ":00";

    const newStart = `${dayStr} ${st}`;
    const newEnd = `${dayStr} ${et}`;

    for (const slot of bookingSlots[dayStr]) {
      if (
        doIntervalsOverlap(
          newStart,
          newEnd,
          slot.start_datetime,
          slot.end_datetime,
        )
      ) {
        return true;
      }
    }
    return false;
  }

  // -----------------------------------------
  // Toggle day selection for creation
  // -----------------------------------------
  function handleDayToggle(day) {
    if (!day) return;
    const mm = String(monthIndex + 1).padStart(2, "0");
    const dd = String(day).padStart(2, "0");
    const dayStr = `${year}-${mm}-${dd}`;

    // If clashing => do nothing
    if (dayHasClash(dayStr)) {
      return;
    }

    setSelectedDay(dayStr);
    setSelectedDaysForCreation((prev) => {
      if (prev.includes(dayStr)) {
        // remove it
        return prev.filter((d) => d !== dayStr);
      } else {
        // add it
        return [...prev, dayStr];
      }
    });
  }

  function handleDeselectAll() {
    setSelectedDaysForCreation([]);
  }

  // -----------------------------------------
  // Create multiple booking slots
  // -----------------------------------------
  async function handleCreateBookingSlotsMulti() {
    try {
      setIsLoading(true);
      setError("");

      let startTime = newSlot.start_time.trim();
      let endTime = newSlot.end_time.trim();
      if (/^\d{2}:\d{2}$/.test(startTime)) {
        startTime += ":00";
      }
      if (/^\d{2}:\d{2}$/.test(endTime)) {
        endTime += ":00";
      }

      const basePayload = {
        resource_id: resource.resource_id,
        status: newSlot.status,
        start_time: startTime,
        end_time: endTime,
      };
      if (
        resource.resource_id === "33333333-3333-3333-3333-333333333333" &&
        resource.space_id
      ) {
        basePayload.space_ids = [resource.space_id];
      }

      for (const dayStr of selectedDaysForCreation) {
        const payload = { ...basePayload, date: dayStr };
        if (basePayload.space_ids) {
          await adminAPI.createSingleBookingSlotSpace(payload);
        } else {
          await adminAPI.createSingleBookingSlotNonSpace(payload);
        }
      }

      // Reset
      setNewSlot({ start_time: "", end_time: "", status: "open" });
      setSelectedDaysForCreation([]);
      await fetchBookingSlots();
      setOpenDialog(false); // Optionally close
    } catch (err) {
      console.error("Error creating multiple booking slots:", err);
      setError("Failed to create booking slots.");
    } finally {
      setIsLoading(false);
    }
  }

  // -----------------------------------------
  // Edit/Update an existing booking slot
  // -----------------------------------------
  function handleEdit(slot) {
    setEditSlotId(slot.booking_slot_id);
    setEditSlotData({
      start_datetime: slot.start_datetime,
      end_datetime: slot.end_datetime,
      status: slot.status,
    });
  }

  function handleCancelEdit() {
    setEditSlotId(null);
    setEditSlotData({ start_datetime: "", end_datetime: "", status: "open" });
  }

  async function handleSaveEdit(bookingSlotId) {
    try {
      setIsLoading(true);
      setError("");

      const updateFields = {
        status: editSlotData.status,
        start_datetime: editSlotData.start_datetime,
        end_datetime: editSlotData.end_datetime,
      };

      await adminAPI.updateBookingSlot(bookingSlotId, updateFields);

      setEditSlotId(null);
      setEditSlotData({ start_datetime: "", end_datetime: "", status: "open" });
      await fetchBookingSlots();
    } catch (err) {
      console.error("Error updating booking slot:", err);
      setError("Failed to update booking slot.");
    } finally {
      setIsLoading(false);
    }
  }

  // -----------------------------------------
  // Delete booking slot
  // -----------------------------------------
  async function handleDeleteBookingSlot(bookingSlotId) {
    try {
      setIsLoading(true);
      setError("");

      await adminAPI.deleteBookingSlot(bookingSlotId);
      await fetchBookingSlots();
    } catch (err) {
      console.error("Error deleting booking slot:", err);
      setError("Failed to delete booking slot.");
    } finally {
      setIsLoading(false);
    }
  }

  // -----------------------------------------
  // Existing slots for the selected day
  // -----------------------------------------
  const timeSlotsForSelectedDay = bookingSlots[selectedDay] || [];

  // -----------------------------------------
  // RENDER
  // -----------------------------------------
  if (isLoading) {
    return (
      <div className="space-y-4">
        <Skeleton className="h-6 w-1/2" />
        <Skeleton className="h-4 w-full" />
        <Skeleton className="h-64 w-full" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive" className="mb-4">
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  const daysArray = getDaysInMonth(year, monthIndex);

  return (
    <div className="mt-2">
      {/* Button to show the Dialog with calendar */}
      <Dialog open={openDialog} onOpenChange={setOpenDialog}>
        <DialogTrigger asChild>
          <Button variant="outline">View/Create Slots</Button>
        </DialogTrigger>

        {/* 
          Make dialog responsive and scrollable if needed:
          max-h-[80vh], overflow-y-auto
        */}
        <DialogContent className="sm:max-w-xl max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>View / Create Booking Slots</DialogTitle>
            <DialogDescription>
              Select a time range and pick days for new slots. Existing day
              slots will appear below.
            </DialogDescription>
          </DialogHeader>

          {/* MULTI-DAY CREATE FORM */}
          <div className="mt-2 border-b pb-3 mb-3">
            <h5 className="text-md font-semibold mb-2">New Slot Time Range</h5>
            <div className="flex items-center gap-2 flex-wrap mb-2">
              <Input
                type="time"
                value={newSlot.start_time}
                onChange={(e) =>
                  setNewSlot((prev) => ({
                    ...prev,
                    start_time: e.target.value,
                  }))
                }
                placeholder="Start (HH:MM)"
                className="w-28"
              />
              <Input
                type="time"
                value={newSlot.end_time}
                onChange={(e) =>
                  setNewSlot((prev) => ({ ...prev, end_time: e.target.value }))
                }
                placeholder="End (HH:MM)"
                className="w-28"
              />
              <Select
                value={newSlot.status}
                onValueChange={(val) =>
                  setNewSlot((prev) => ({ ...prev, status: val }))
                }
              >
                <SelectTrigger className="w-32">
                  <SelectValue placeholder="Status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="open">Open</SelectItem>
                  <SelectItem value="closed">Closed</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <p className="text-sm text-muted-foreground mb-2">
              If the chosen time range clashes with existing slots, that day is
              disabled.
            </p>
            <Button variant="secondary" size="sm" onClick={handleDeselectAll}>
              Deselect All
            </Button>
          </div>

          {/* CALENDAR for the month */}
          <div className="border rounded p-3">
            {/* Month nav */}
            <div className="flex items-center justify-between mb-4">
              <Button type="button" variant="ghost" onClick={handlePrevMonth}>
                <ChevronLeft className="w-5 h-5" />
              </Button>
              <h2 className="text-lg font-medium">
                {getFormattedMonth(currentMonthDate)}
              </h2>
              <Button type="button" variant="ghost" onClick={handleNextMonth}>
                <ChevronRight className="w-5 h-5" />
              </Button>
            </div>

            {/* 
              grid for days: add more vertical space => gap-y-2
              keep horizontal gap small => gap-x-1
            */}
            <div className="grid grid-cols-7 gap-x-1 gap-y-2 justify-items-center">
              {DAYS_OF_WEEK.map((dow) => (
                <div
                  key={dow}
                  className="text-center font-medium text-gray-600"
                >
                  {dow}
                </div>
              ))}

              {/* Actual days in this month */}
              {daysArray.map((day, idx) => {
                if (!day) {
                  // blank cell
                  return <div key={idx} className="w-10 h-10" />;
                }

                const dd = String(day).padStart(2, "0");
                const dayStr = `${year}-${String(monthIndex + 1).padStart(2, "0")}-${dd}`;
                const hasSlots = !!bookingSlots[dayStr];
                const isSelectedDay = selectedDay === dayStr;

                // For multi-day creation
                const isSelectedForCreation =
                  selectedDaysForCreation.includes(dayStr);

                // Check if day is clashing => if so, day is "disabled"
                const isClashing = dayHasClash(dayStr);

                // Base day classes
                const dayClasses = [
                  "flex items-center justify-center w-10 h-10 rounded-md text-sm",
                ];

                // Clashing => "disabled" look
                if (isClashing) {
                  dayClasses.push(
                    "bg-destructive text-destructive-foreground opacity-70",
                  );
                } else if (hasSlots) {
                  // Has existing slots => primary color
                  dayClasses.push(
                    isSelectedDay
                      ? "bg-primary text-primary-foreground"
                      : "bg-primary-light text-primary-foreground hover:bg-primary/90 cursor-pointer",
                  );
                } else {
                  // No existing slot => "bg-muted"
                  dayClasses.push(
                    "bg-muted text-muted-foreground hover:bg-muted/90 cursor-pointer",
                  );
                }

                // Thin ring with a small offset so there's a bit of space
                if (isSelectedForCreation) {
                  dayClasses.push(
                    "ring-1 ring-offset-1 ring-offset-background ring-primary",
                  );
                }

                return (
                  <div
                    key={idx}
                    className={dayClasses.join(" ")}
                    style={isClashing ? { pointerEvents: "none" } : {}}
                    onClick={(e) => {
                      if (!isClashing) {
                        e.stopPropagation();
                        handleDayClick(day);
                        handleDayToggle(day);
                      }
                    }}
                  >
                    {day}
                  </div>
                );
              })}
            </div>
          </div>

          {/* TIME SLOTS for the SELECTED DAY (existing booking slots) */}
          {selectedDay && (
            <div className="mt-4">
              <h3 className="text-md font-semibold mb-2">
                Booking Slots for {selectedDay}
              </h3>
              {!bookingSlots[selectedDay] ||
              bookingSlots[selectedDay].length === 0 ? (
                <p className="text-sm italic">No slots available.</p>
              ) : (
                bookingSlots[selectedDay].map((slot, i) => {
                  const isEditing = slot.booking_slot_id === editSlotId;

                  if (isEditing) {
                    const { datePart: startDatePart, timePart: startTimePart } =
                      splitDateTime(editSlotData.start_datetime);
                    const { datePart: endDatePart, timePart: endTimePart } =
                      splitDateTime(editSlotData.end_datetime);

                    return (
                      <div
                        key={i}
                        className="border p-2 mb-2 rounded flex flex-col gap-2"
                      >
                        {/* Start date/time */}
                        <div className="flex items-center gap-2">
                          <Input
                            type="date"
                            value={startDatePart}
                            onChange={(e) => {
                              const newVal = combineDateTime(
                                e.target.value,
                                startTimePart,
                              );
                              setEditSlotData((prev) => ({
                                ...prev,
                                start_datetime: newVal,
                              }));
                            }}
                          />
                          <Input
                            type="time"
                            value={startTimePart.replace(":00", "")}
                            onChange={(e) => {
                              const newVal = combineDateTime(
                                startDatePart,
                                e.target.value,
                              );
                              setEditSlotData((prev) => ({
                                ...prev,
                                start_datetime: newVal,
                              }));
                            }}
                          />
                        </div>
                        {/* End date/time */}
                        <div className="flex items-center gap-2">
                          <Input
                            type="date"
                            value={endDatePart}
                            onChange={(e) => {
                              const newVal = combineDateTime(
                                e.target.value,
                                endTimePart,
                              );
                              setEditSlotData((prev) => ({
                                ...prev,
                                end_datetime: newVal,
                              }));
                            }}
                          />
                          <Input
                            type="time"
                            value={endTimePart.replace(":00", "")}
                            onChange={(e) => {
                              const newVal = combineDateTime(
                                endDatePart,
                                e.target.value,
                              );
                              setEditSlotData((prev) => ({
                                ...prev,
                                end_datetime: newVal,
                              }));
                            }}
                          />
                        </div>
                        {/* Status */}
                        <Select
                          value={editSlotData.status}
                          onValueChange={(val) =>
                            setEditSlotData((prev) => ({
                              ...prev,
                              status: val,
                            }))
                          }
                        >
                          <SelectTrigger className="w-32">
                            <SelectValue placeholder="Status" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="open">Open</SelectItem>
                            <SelectItem value="closed">Closed</SelectItem>
                          </SelectContent>
                        </Select>

                        {/* Save/Cancel */}
                        <div className="flex gap-2">
                          <Button
                            variant="default"
                            size="sm"
                            onClick={() => handleSaveEdit(slot.booking_slot_id)}
                          >
                            Save
                          </Button>
                          <Button
                            variant="secondary"
                            size="sm"
                            onClick={handleCancelEdit}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    );
                  } else {
                    // Non-edit view
                    return (
                      <div
                        key={i}
                        className="border p-2 mb-2 rounded flex flex-col sm:flex-row sm:justify-between sm:items-center"
                      >
                        <div className="text-sm">
                          <p>
                            <strong>Start:</strong> {slot.start_datetime}
                          </p>
                          <p>
                            <strong>End:</strong> {slot.end_datetime}
                          </p>
                          <p>
                            <strong>Status:</strong> {slot.status}
                          </p>
                        </div>
                        <div className="mt-2 sm:mt-0 flex gap-2">
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => handleEdit(slot)}
                          >
                            {/* Pen icon for editing */}
                            <Pen className="w-4 h-4" />
                          </Button>
                          <Button
                            variant="destructive"
                            size="sm"
                            onClick={() =>
                              handleDeleteBookingSlot(slot.booking_slot_id)
                            }
                          >
                            <Trash2 className="w-4 h-4" />
                          </Button>
                        </div>
                      </div>
                    );
                  }
                })
              )}
            </div>
          )}

          <DialogFooter className="mt-4 flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2">
            {/* Show the selected days nicely at the bottom */}
            {selectedDaysForCreation.length > 0 ? (
              <div className="text-sm">
                <strong>Selected Days:</strong>{" "}
                {selectedDaysForCreation.join(", ")}
              </div>
            ) : (
              <div className="text-sm text-muted-foreground">
                No days selected.
              </div>
            )}

            {/* Create booking slots button */}
            <Button onClick={handleCreateBookingSlotsMulti}>
              Create Booking Slots
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default BookingSlotList;
