import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setFormField,
  resetForm,
  setMosqueGlideIdAndUserEmail,
} from "store/forms/marriage-form/marriageFormSlice";
import { createMarriageBooking } from "store/forms/marriage-form/thunks";
import { Input } from "components/shadcn/Input";
import { Label } from "components/shadcn/Label";
import { Button } from "components/shadcn/Button";
import { Textarea } from "components/shadcn/Textarea";
import Calendar from "./Calendar";
import UploadSingleFile from "./UploadSingleFile";
import UploadMultipleFiles from "./UploadMultipleFiles";
import ThankYou from "./ThankYou";
import { MARRIAGE_FORM_LABELS } from "constants/formsConstants";

function MarriageForm() {
  const dispatch = useDispatch();
  const { formData, isSubmitting, submissionError, showThankYou } = useSelector(
    (state) => state.marriageForm,
  );
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mosqueGlideId = searchParams.get("mosque_glide_id");
  const userEmail = searchParams.get("user_email");
  const lang = searchParams.get("lang") || "en";

  useEffect(() => {
    if (mosqueGlideId && userEmail) {
      dispatch(setMosqueGlideIdAndUserEmail({ mosqueGlideId, userEmail }));
    }
  }, [dispatch, mosqueGlideId, userEmail]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(setFormField({ field: name, value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createMarriageBooking()).then((action) => {
      if (action.meta.requestStatus === "fulfilled") {
        dispatch(resetForm());
      }
    });
  };

  if (showThankYou) {
    return <ThankYou lang={lang} />;
  }

  const labels = MARRIAGE_FORM_LABELS[lang];
  const isRTL = lang === "ku";

  return (
    <div className={`max-w-md mx-auto p-4`}>
      <form onSubmit={handleSubmit}>
        {/* GROOM NAME */}
        <div className="mb-4" dir={isRTL ? "rtl" : "ltr"}>
          <Label htmlFor="groomName">{labels.groomName}</Label>
          <Input
            type="text"
            name="groomName"
            value={formData.groomName || ""}
            onChange={handleChange}
            className="mt-1 block w-full"
            required
            placeholder={labels.groomNamePlaceholder}
          />
        </div>

        {/* GROOM PHONE NUMBER */}
        <div className="mb-4" dir={isRTL ? "rtl" : "ltr"}>
          <Label htmlFor="groomPhoneNumber">{labels.groomPhoneNumber}</Label>
          <Input
            type="tel"
            name="groomPhoneNumber"
            value={formData.groomPhoneNumber || ""}
            onChange={handleChange}
            className="mt-1 block w-full"
            required
            placeholder={labels.groomPhoneNumberPlaceholder}
          />
        </div>

        {/* ADDITIONAL INFORMATION */}
        <div className="mb-4" dir={isRTL ? "rtl" : "ltr"}>
          <Label htmlFor="additionalInformation">
            {labels.additionalInformation}
          </Label>
          <Textarea
            name="additionalInformation"
            id="additionalInformation"
            value={formData.additionalInformation || ""}
            onChange={handleChange}
            className="mt-1 block w-full"
            placeholder={labels.additionalInformationPlaceholder}
          />
        </div>

        {/* ID DOCUMENT */}
        <div className="mb-4">
          <UploadSingleFile labels={labels} lang={lang} />
        </div>

        {/* ADDITIONAL DOCUMENTS */}
        <div className="mb-4">
          <UploadMultipleFiles labels={labels} lang={lang} />
        </div>

        {/* CALENDAR */}
        <div className="mb-4">
          <Calendar />
        </div>

        {submissionError && (
          <div className="mb-4 text-red-500">{submissionError}</div>
        )}

        {/* SUBMIT BUTTON */}
        <div className="flex justify-center mt-6">
          <Button
            type="submit"
            className="w-full max-w-xs py-3 text-lg bg-primary text-primary-foreground"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default MarriageForm;
