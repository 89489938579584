import { VALIDATION_ERRORS } from "constants/formsConstants";

const validatePhoneNumber = (phone) => {
  const stripped = phone.replace(/\s+/g, "");
  const regex = /^(\+447|07)\d+$/;
  return regex.test(stripped);
};

export function validateField(fieldName, value, lang = "en") {
  const messages = VALIDATION_ERRORS[lang] || VALIDATION_ERRORS["en"];

  switch (fieldName) {
    case "name":
      if (!value || value.trim().length < 5) {
        return messages.name;
      }
      return "";
    case "phoneNumber":
      if (!value || !validatePhoneNumber(value)) {
        return messages.phoneNumber;
      }
      return "";
    case "purposeOfHire":
      if (!value || value.trim().length < 3) {
        return messages.purposeOfHire;
      }
      return "";
    case "numberOfPeople":
      if (!value || Number(value) <= 0) {
        return messages.numberOfPeople;
      }
      return "";
    default:
      return "";
  }
}

export function validateSpacesFormStep1(formData, lang = "en") {
  const errors = {};
  const messages = VALIDATION_ERRORS[lang] || VALIDATION_ERRORS["en"];

  // Name
  if (!formData.name || formData.name.trim().length < 5) {
    errors.name = messages.name;
  }

  // Phone
  if (!formData.phoneNumber || !validatePhoneNumber(formData.phoneNumber)) {
    errors.phoneNumber = messages.phoneNumber;
  }

  // Purpose
  if (!formData.purposeOfHire || formData.purposeOfHire.trim().length < 3) {
    errors.purposeOfHire = messages.purposeOfHire;
  }

  // Number of People
  if (!formData.numberOfPeople || Number(formData.numberOfPeople) <= 0) {
    errors.numberOfPeople = messages.numberOfPeople;
  }

  return errors;
}

export function validateSpacesFormStep2(formData, spaceIds, lang = "en") {
  const errors = {};
  const messages = VALIDATION_ERRORS[lang] || VALIDATION_ERRORS["en"];

  // Must select at least one space
  if (!spaceIds || spaceIds.length === 0) {
    errors.spaceIds = messages.spaceIds;
  }

  // Must have at least one time slot
  if (!formData.selectedTimeSlots || formData.selectedTimeSlots.length === 0) {
    errors.selectedTimeSlots = messages.selectedTimeSlots;
  }

  return errors;
}

export function validateSpacesFormStep3(formData, lang = "en") {
  const errors = {};
  const messages = VALIDATION_ERRORS[lang] || VALIDATION_ERRORS["en"];

  // Payment Type
  if (!formData.paymentType) {
    errors.paymentType = messages.paymentType;
  }

  // Terms Agreement
  if (!formData.agreedToTerms) {
    errors.agreedToTerms = messages.agreedToTerms;
  }

  return errors;
}
