import React from "react";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "components/shadcn/Tooltip";
import { Info } from "lucide-react";

const InfoTooltip = ({ content, style = {} }) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div
            style={{
              background: "transparent",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              padding: "0 10px",
            }}
          >
            <Info size={20} color="#6B7280" />
          </div>
        </TooltipTrigger>
        <TooltipContent style={{ textAlign: "left", ...style }}>
          {content}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default InfoTooltip;
