const isProduction = window.location.href.includes("mosquely.com");

const stripePublicKeyDev = process.env["REACT_APP_STRIPE_PUBLIC_KEY_DEV"];
const stripePublicKeyProd = process.env["REACT_APP_STRIPE_PUBLIC_KEY_PROD"];
const apiUrlDev = process.env["REACT_APP_API_URL_DEV"];
const apiUrlProd = process.env["REACT_APP_API_URL_PROD"];

export const stripePublicKey = isProduction
  ? stripePublicKeyProd
  : stripePublicKeyDev;

export const apiUrl = isProduction ? apiUrlProd : apiUrlDev;
