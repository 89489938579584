import { createSlice } from "@reduxjs/toolkit";
import { fetchOpenBookingSlots } from "./thunks";
import {
  getPreviousMonth,
  getNextMonth,
  getDaysOpenForMonth,
  getFirstDayOfCurrentMonth,
} from "utils/dateUtils";
import { SPACES_OPTIONS } from "constants/formsConstants";

const initialState = {
  mosqueGlideId: "",
  userEmail: "",
  formData: {
    name: "",
    phoneNumber: "",
    purposeOfHire: "",
    numberOfPeople: 0,
    paymentType: "",
    paymentAmount: 0,
    agreedToTerms: false,
    additionalInformation: "",
    selectedTimeSlots: [],
  },
  spaceIds: [],
  currentMonth: getFirstDayOfCurrentMonth(),
  currentDaysOpenThisMonth: {},
  selectedDay: null,
  timeSlots: [],
  isSubmitting: false,
  submissionError: null,
  showThankYou: false,
  isLoadingSlots: false,
  currentStep: 1,
  clientSecret: null,
  paymentId: null,
};

const spacesFormSlice = createSlice({
  name: "spacesForm",
  initialState,
  reducers: {
    setMosqueGlideIdAndUserEmail: (state, action) => {
      state.mosqueGlideId = action.payload.mosqueGlideId;
      state.userEmail = action.payload.userEmail;
    },
    setFormField: (state, action) => {
      const { field, value } = action.payload;
      state.formData[field] = value;
    },
    resetForm: (state) => {
      state.formData = {
        name: "",
        phoneNumber: "",
        purposeOfHire: "",
        numberOfPeople: 0,
        paymentType: "",
        paymentAmount: 0,
        agreedToTerms: false,
        additionalInformation: "",
        selectedTimeSlots: [],
      };
      state.spaceIds = [];
      state.selectedDay = null;
      state.timeSlots = [];
      state.currentDaysOpenThisMonth = {};
      state.currentStep = 1;
    },
    setIsSubmitting: (state, action) => {
      state.isSubmitting = action.payload;
    },
    setSubmissionError: (state, action) => {
      state.submissionError = action.payload;
    },
    setShowThankYou: (state, action) => {
      state.showThankYou = action.payload;
    },
    setCurrentMonth: (state, action) => {
      state.currentMonth = action.payload;
    },
    goToPreviousMonth: (state) => {
      state.currentMonth = getPreviousMonth(state.currentMonth);
    },
    goToNextMonth: (state) => {
      state.currentMonth = getNextMonth(state.currentMonth);
    },
    setSelectedDay: (state, action) => {
      state.selectedDay = action.payload;
      const slots = state.currentDaysOpenThisMonth[action.payload] || [];
      const groupedSlots = new Map();
      slots.forEach((slot) => {
        const timeRangeKey = `${slot.start_datetime}-${slot.end_datetime}`;
        if (!groupedSlots.has(timeRangeKey)) {
          groupedSlots.set(timeRangeKey, {
            start: slot.start_datetime,
            end: slot.end_datetime,
            details: [slot],
          });
        } else {
          groupedSlots.get(timeRangeKey).details.push(slot);
        }
      });
      state.timeSlots = Array.from(groupedSlots.values());
      state.formData.selectedTimeSlots = [];
    },
    setSelectedTimeSlot: (state, action) => {
      state.formData.selectedTimeSlots = action.payload;
    },
    clearSelectedDay: (state) => {
      state.selectedDay = null;
      state.timeSlots = [];
      state.formData.selectedTimeSlots = [];
    },
    toggleSpaceId: (state, action) => {
      const spaceId = action.payload;
      if (state.spaceIds.includes(spaceId)) {
        state.spaceIds = state.spaceIds.filter((id) => id !== spaceId);
      } else {
        state.spaceIds.push(spaceId);
      }
      const selectedSpaces = SPACES_OPTIONS.filter((space) =>
        state.spaceIds.includes(space.id),
      );
      const totalCost = selectedSpaces.reduce(
        (acc, space) => acc + space.cost,
        0,
      );
      state.formData.paymentAmount = totalCost;
    },
    nextStep: (state) => {
      if (state.currentStep < 4) {
        state.currentStep += 1;
      }
    },
    prevStep: (state) => {
      if (state.currentStep > 1) {
        state.currentStep -= 1;
      }
    },
    setClientSecret: (state, action) => {
      state.clientSecret = action.payload;
    },
    setPaymentId: (state, action) => {
      state.paymentId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOpenBookingSlots.pending, (state) => {
        state.isLoadingSlots = true;
      })
      .addCase(fetchOpenBookingSlots.fulfilled, (state, action) => {
        state.isLoadingSlots = false;
        const bookingSlots = action.payload.booking_slots || {};
        state.currentDaysOpenThisMonth = getDaysOpenForMonth(
          state.currentMonth,
          bookingSlots,
        );
      })
      .addCase(fetchOpenBookingSlots.rejected, (state, action) => {
        state.isLoadingSlots = false;
        state.submissionError = action.payload;
      });
  },
});

export const {
  setMosqueGlideIdAndUserEmail,
  setFormField,
  resetForm,
  setCurrentMonth,
  goToPreviousMonth,
  goToNextMonth,
  setSelectedDay,
  setSelectedTimeSlot,
  clearSelectedDay,
  setIsSubmitting,
  setSubmissionError,
  setShowThankYou,
  toggleSpaceId,
  nextStep,
  prevStep,
  setClientSecret,
  setPaymentId,
} = spacesFormSlice.actions;

export default spacesFormSlice.reducer;
