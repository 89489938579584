import React from "react";
import { Button } from "components/shadcn/Button";

const PaymentTypeAndAmount = ({
  paymentType,
  paymentAmount,
  onPaymentTypeChange,
  error,
  labels,
}) => {
  const totalAmount = paymentAmount.toFixed(2);
  let displayAmountSection = null;

  if (paymentType === "deposit") {
    const depositAmount = (paymentAmount / 2).toFixed(2);
    displayAmountSection = (
      <>
        <div className="mb-1 flex items-center justify-between">
          <div className="font-semibold text-md">{labels.total}</div>
          <div className="text-md font-medium">£{totalAmount}</div>
        </div>
        <div className="mb-4 flex items-center justify-between">
          <div className="font-semibold text-md">{labels.amountToPayNow}</div>
          <div className="text-lg font-bold">£{depositAmount}</div>
        </div>
      </>
    );
  } else if (paymentType === "full" || paymentType === "cash") {
    displayAmountSection = (
      <div className="mb-4 flex items-center justify-between">
        <div className="font-semibold text-md">{labels.total}</div>
        <div className="text-lg font-bold">£{totalAmount}</div>
      </div>
    );
  }

  return (
    <div className="mb-4">
      <h4 className="font-semibold mb-2">{labels.paymentType}</h4>
      <div className="mb-4 flex space-x-4">
        <div className="px-2"></div>
        <Button
          type="button"
          variant={paymentType === "deposit" ? "default" : "outline"}
          onClick={() => onPaymentTypeChange("deposit")}
        >
          {labels.deposit}
        </Button>
        <Button
          type="button"
          variant={paymentType === "full" ? "default" : "outline"}
          onClick={() => onPaymentTypeChange("full")}
        >
          {labels.fullPayment}
        </Button>
        <Button
          type="button"
          variant={paymentType === "cash" ? "default" : "outline"}
          onClick={() => onPaymentTypeChange("cash")}
        >
          {labels.cashPayment}
        </Button>
      </div>
      {error && <div className="text-red-500 text-sm">{error}</div>}

      {displayAmountSection}
    </div>
  );
};

export default PaymentTypeAndAmount;
