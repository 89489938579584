import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Button } from "components/shadcn/Button";

function PaymentForm({ onSuccess }) {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setIsSubmitting(true);
    setErrorMessage(null);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {},
      redirect: "if_required",
    });

    if (error) {
      setErrorMessage(error.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      onSuccess();
    }

    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <h3 className="text-xl font-semibold mb-4">Payment Details</h3>
      <PaymentElement />
      <Button
        type="submit"
        className="w-full"
        disabled={!stripe || isSubmitting}
      >
        {isSubmitting ? "Processing..." : "Pay"}
      </Button>
      {errorMessage && <div className="mt-2 text-red-500">{errorMessage}</div>}
    </form>
  );
}

export default PaymentForm;
