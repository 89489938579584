import React from "react";
import { MARRIAGE_FORM_LABELS } from "constants/formsConstants";

function ThankYou({ lang = "en" }) {
  const labels = MARRIAGE_FORM_LABELS[lang];
  const isRTL = lang === "ku";

  return (
    <div
      className={`max-w-md mx-auto p-4 text-center ${isRTL ? "text-right" : "text-left"}`}
      dir={isRTL ? "rtl" : "ltr"}
    >
      <h2 className="text-2xl font-bold mb-4">{labels.thankYouTitle}</h2>
      <p>{labels.thankYouMessage}</p>
    </div>
  );
}

export default ThankYou;
