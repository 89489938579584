import React from "react";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "components/shadcn/Tooltip";
import { Info } from "lucide-react";

const InfoTooltip = ({ content, style, dir = "ltr" }) => {
  const isRTL = dir === "rtl";

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div
            style={{
              background: "transparent",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <Info size={24} color="#6B7280" />
          </div>
        </TooltipTrigger>
        <TooltipContent
          style={{
            textAlign: isRTL ? "right" : "left",
            direction: dir,
            ...style,
          }}
        >
          {content}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default InfoTooltip;
