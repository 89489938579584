export const validateFiles = (files, acceptedTypes, maxSize) => {
  const errors = [];
  files.forEach((file) => {
    if (!acceptedTypes.includes(file.type)) {
      errors.push(`${file.name}: Invalid file type`);
    }
    if (file.size > maxSize) {
      errors.push(
        `${file.name}: File size exceeds ${maxSize / (1024 * 1024)}MB limit`,
      );
    }
  });
  return errors;
};

export const truncateFileName = (name, maxLength = 30) => {
  if (name.length <= maxLength) return name;
  const extIndex = name.lastIndexOf(".");
  const extension = name.slice(extIndex);
  const truncatedName = name.slice(0, maxLength - extension.length - 3);
  return `${truncatedName}...${extension}`;
};

export const handleFileDrop = (event, config) => {
  event.preventDefault();
  const {
    dispatch,
    setErrorAction,
    files,
    maxFiles,
    acceptedTypes,
    maxSize,
    onFilesUpdate,
  } = config;

  dispatch(setErrorAction(null));

  const newFiles = Array.from(event.dataTransfer.files).slice(
    0,
    maxFiles - files.length,
  );

  const validationErrors = validateFiles(newFiles, acceptedTypes, maxSize);

  if (validationErrors.length) {
    dispatch(setErrorAction(validationErrors.join(", ")));
    return;
  }

  onFilesUpdate(newFiles);
};

export const handleFileInputChange = (event, config) => {
  const {
    dispatch,
    setErrorAction,
    files,
    maxFiles,
    acceptedTypes,
    maxSize,
    onFilesUpdate,
  } = config;

  dispatch(setErrorAction(null));

  const newFiles = Array.from(event.target.files).slice(
    0,
    maxFiles - files.length,
  );

  const validationErrors = validateFiles(newFiles, acceptedTypes, maxSize);

  if (validationErrors.length) {
    dispatch(setErrorAction(validationErrors.join(", ")));
    return;
  }

  onFilesUpdate(newFiles);
};
