import React, { useState } from "react";
import { AlertCircle, CheckCircle2 } from "lucide-react";
import { Alert, AlertTitle, AlertDescription } from "components/shadcn/Alert";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "components/shadcn/Card";
import ResourceList from "components/admin/resource/ResourceList";
import Bookings from "components/admin/resource/Bookings";

function Home() {
  const [mosqueId] = useState("11111111-1111-1111-1111-111111111111");
  const [mosqueGlideId] = useState("phUqPI4hSaW2tzhP48FSpw");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  return (
    <div className="space-y-4">
      <h1 className="text-xl font-bold">Mosque Dashboard</h1>

      {errorMessage && (
        <Alert variant="destructive" className="mb-4">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{errorMessage}</AlertDescription>
        </Alert>
      )}

      {successMessage && (
        <Alert variant="success" className="mb-4">
          <CheckCircle2 className="h-4 w-4" />
          <AlertTitle>Success</AlertTitle>
          <AlertDescription>{successMessage}</AlertDescription>
        </Alert>
      )}

      <Card>
        <CardHeader>
          <CardTitle>Bookings Calendar</CardTitle>
          <CardDescription>Manage your bookings here</CardDescription>
        </CardHeader>
        <CardContent>
          <Bookings />
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Resource List</CardTitle>
          <CardDescription>Manage your resources here</CardDescription>
        </CardHeader>
        <CardContent>
          <ResourceList mosqueId={mosqueId} mosqueGlideId={mosqueGlideId} />
        </CardContent>
      </Card>
    </div>
  );
}

export default Home;
