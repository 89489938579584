import { useCallback } from "react";
import {
  setFormField,
  toggleSpaceId,
  setShowThankYou,
  nextStep,
  prevStep,
} from "store/forms/spaces-form/spacesFormSlice";
import {
  createPaymentIntent,
  createSpacesBooking,
} from "store/forms/spaces-form/thunks";
import {
  validateField,
  validateSpacesFormStep1,
  validateSpacesFormStep2,
  validateSpacesFormStep3,
} from "utils/validationUtils";

export default function useSpacesFormHandlers({
  dispatch,
  formData,
  spaceIds,
  currentStep,
  errors,
  setErrors,
  lang,
}) {
  const handleChange = useCallback(
    (e) => {
      const { name, value, type, checked } = e.target;
      const fieldValue = type === "checkbox" ? checked : value;
      dispatch(setFormField({ field: name, value: fieldValue }));

      if (currentStep === 1 && errors[name]) {
        const errorMessage = validateField(name, fieldValue, lang);
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          if (!errorMessage) {
            delete newErrors[name];
          } else {
            newErrors[name] = errorMessage;
          }
          return newErrors;
        });
      }
    },
    [dispatch, currentStep, errors, lang, setErrors],
  );

  const handleBlur = useCallback(
    (e) => {
      const { name, value } = e.target;
      if (
        currentStep === 1 &&
        ["name", "phoneNumber", "purposeOfHire", "numberOfPeople"].includes(
          name,
        )
      ) {
        const errorMessage = validateField(name, value, lang);
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          if (errorMessage) {
            newErrors[name] = errorMessage;
          } else {
            delete newErrors[name];
          }
          return newErrors;
        });
      }
    },
    [currentStep, lang, setErrors],
  );

  const handleSpaceChange = useCallback(
    (spaceId) => {
      dispatch(toggleSpaceId(spaceId));

      if (errors.spaceIds || errors.selectedTimeSlots) {
        const validationErrors = validateSpacesFormStep2(
          formData,
          [...spaceIds, spaceId],
          lang,
        );
        setErrors(validationErrors);
      }
    },
    [dispatch, errors, formData, spaceIds, lang, setErrors],
  );

  const handleTimeSlotChange = useCallback(() => {
    if (errors.selectedTimeSlots || errors.spaceIds) {
      const validationErrors = validateSpacesFormStep2(
        formData,
        spaceIds,
        lang,
      );
      setErrors(validationErrors);
    }
  }, [errors, formData, spaceIds, lang, setErrors]);

  const handlePaymentTypeChange = useCallback(
    (value) => {
      dispatch(setFormField({ field: "paymentType", value }));
      if (errors.paymentType || errors.agreedToTerms) {
        const validationErrors = validateSpacesFormStep3(
          { ...formData, paymentType: value },
          lang,
        );
        setErrors(validationErrors);
      }
    },
    [dispatch, errors, formData, lang, setErrors],
  );

  const handleAgreedChange = useCallback(
    (checked) => {
      dispatch(setFormField({ field: "agreedToTerms", value: checked }));
      if (errors.paymentType || errors.agreedToTerms) {
        const validationErrors = validateSpacesFormStep3(
          { ...formData, agreedToTerms: checked },
          lang,
        );
        setErrors(validationErrors);
      }
    },
    [dispatch, errors, formData, lang, setErrors],
  );

  const handleNext = useCallback(async () => {
    let valid = true;

    if (currentStep === 1) {
      const validationErrors = validateSpacesFormStep1(formData, lang);
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length > 0) {
        valid = false;
      }
    } else if (currentStep === 2) {
      const validationErrors = validateSpacesFormStep2(
        formData,
        spaceIds,
        lang,
      );
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length > 0) {
        valid = false;
      }
    } else if (currentStep === 3) {
      const validationErrors = validateSpacesFormStep3(formData, lang);
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length > 0) {
        valid = false;
        return;
      }

      // Attempt booking or payment creation
      if (formData.paymentType === "cash") {
        try {
          await dispatch(createSpacesBooking()).unwrap();
          dispatch(setShowThankYou(true));
        } catch (error) {
          console.error("Booking creation failed", error);
          return;
        }
      } else {
        try {
          await dispatch(createPaymentIntent()).unwrap();
          dispatch(nextStep());
        } catch (error) {
          console.error("Payment Intent creation failed", error);
          return;
        }
      }
    }

    if (!valid) return;
    if (currentStep < 3) {
      dispatch(nextStep());
    }
  }, [dispatch, formData, spaceIds, currentStep, lang, setErrors]);

  const handlePrev = useCallback(() => {
    dispatch(prevStep());
  }, [dispatch]);

  return {
    handleChange,
    handleBlur,
    handleSpaceChange,
    handleTimeSlotChange,
    handlePaymentTypeChange,
    handleAgreedChange,
    handleNext,
    handlePrev,
  };
}
