import React from "react";
import { FileText, CloudUpload, XCircle } from "lucide-react";
import { Progress } from "components/shadcn/Progress";
import InfoTooltip from "./InfoTooltip";
import { useDispatch, useSelector } from "react-redux";
import {
  setSingleFileError,
  setSingleFileName,
} from "store/forms/marriage-form/marriageFormSlice";
import {
  uploadSingleIdDocument,
  deleteSingleIdDocument,
} from "store/forms/marriage-form/thunks";
import { MAX_FILE_SIZE, ACCEPTED_FILE_TYPES } from "constants/formsConstants";
import {
  handleFileDrop,
  handleFileInputChange,
  truncateFileName,
} from "utils/uploadUtils";

const UploadSingleFile = ({ labels, lang }) => {
  const dispatch = useDispatch();
  const {
    singleFileError,
    singleFileName,
    singleFileUploadProgress,
    isSingleFileUploaded,
    isDeletingSingleFile,
  } = useSelector((state) => state.marriageForm.uploadSingleFile);

  const isRTL = lang === "ku";

  const onFilesUpdate = (files) => {
    const file = files[0];
    dispatch(setSingleFileName(file.name));
    dispatch(uploadSingleIdDocument({ file }));
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    dispatch(deleteSingleIdDocument());
  };

  return (
    <div
      onDrop={(e) =>
        handleFileDrop(e, {
          dispatch,
          setErrorAction: setSingleFileError,
          files: singleFileName ? [singleFileName] : [],
          maxFiles: 1,
          acceptedTypes: ACCEPTED_FILE_TYPES,
          maxSize: MAX_FILE_SIZE,
          onFilesUpdate,
        })
      }
      onDragOver={(e) => e.preventDefault()}
      onClick={
        !singleFileName && !isDeletingSingleFile
          ? () => document.getElementById("file-upload").click()
          : null
      }
      style={{
        background: "#FAFAFA",
        border: "2px dashed #ccc",
        borderRadius: "8px",
        padding: "20px",
        textAlign: isRTL ? "right" : "center",
        cursor:
          !singleFileName && !isDeletingSingleFile ? "pointer" : "default",
        position: "relative",
        width: "100%",
        minHeight: "150px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        direction: isRTL ? "rtl" : "ltr",
      }}
    >
      <input
        type="file"
        accept={ACCEPTED_FILE_TYPES.join(",")}
        onChange={(e) =>
          handleFileInputChange(e, {
            dispatch,
            setErrorAction: setSingleFileError,
            files: singleFileName ? [singleFileName] : [],
            maxFiles: 1,
            acceptedTypes: ACCEPTED_FILE_TYPES,
            maxSize: MAX_FILE_SIZE,
            onFilesUpdate,
          })
        }
        style={{ display: "none" }}
        id="file-upload"
      />

      {singleFileName && !isDeletingSingleFile && (
        <XCircle
          size={30}
          onClick={handleDelete}
          style={{
            position: "absolute",
            top: "10px",
            [isRTL ? "left" : "right"]: "10px",
            cursor: "pointer",
          }}
        />
      )}

      {isDeletingSingleFile ? (
        <p>{labels.deleting}</p>
      ) : singleFileName ? (
        isSingleFileUploaded ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FileText size={40} color="#6B7280" />
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "90%",
                fontWeight: "bold",
                marginTop: "10px",
              }}
            >
              {truncateFileName(singleFileName)}
            </span>
          </div>
        ) : (
          <Progress value={singleFileUploadProgress} className="w-full" />
        )
      ) : (
        <>
          <CloudUpload
            size={40}
            color="#6B7280"
            style={{ marginBottom: "10px" }}
          />
          <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
            {labels.uploadIdDocument}
          </p>
          <p style={{ fontSize: "10px", color: "#6B7280" }}>
            {labels.uploadIdDocumentNote}
          </p>
        </>
      )}

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          [isRTL ? "left" : "right"]: "10px",
        }}
      >
        <InfoTooltip
          content={
            <div>
              <p>
                <strong>{labels.acceptedIdDocuments}</strong>
              </p>
              <ul>
                <li>{labels.birthCertificate}</li>
                <li>{labels.drivingLicense}</li>
                <li>{labels.passport}</li>
                <li>{labels.otherId}</li>
              </ul>
            </div>
          }
          dir={lang === "ku" ? "rtl" : "ltr"}
        />
      </div>

      {singleFileError && !isDeletingSingleFile && (
        <p
          style={{
            color: "red",
            marginTop: "10px",
            textAlign: isRTL ? "right" : "left",
          }}
        >
          {singleFileError}
        </p>
      )}
    </div>
  );
};

export default UploadSingleFile;
