import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import {
  MARRIAGE_RESOURCE_ID,
  MARRIAGE_SPACE_IDS,
  MARRIAGE_RESOURCE_NAME,
} from "constants/formsConstants";

import {
  setSingleFileUploadProgress,
  addMultipleFile,
  updateMultipleFileProgress,
  markMultipleFileUploaded,
  markMultipleFileError,
  markAllMultipleFilesDeleting,
  clearMultipleFilesUpload,
  setIsSubmitting,
  setSubmissionError,
  setShowThankYou,
} from "./marriageFormSlice";
import { apiUrl } from "config";

// Get Open Booking Slots
export const fetchOpenBookingSlots = createAsyncThunk(
  "marriageForm/fetchOpenBookingSlots",
  async ({ startDate }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${apiUrl}/booking_slots/get_open_booking_slots`,
        {
          resource_id: MARRIAGE_RESOURCE_ID,
          space_ids: MARRIAGE_SPACE_IDS,
          start_date: startDate,
        },
      );
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || "Failed to fetch booking slots";
      return rejectWithValue(errorMessage);
    }
  },
);

// Upload Single ID Document
export const uploadSingleIdDocument = createAsyncThunk(
  "marriageForm/uploadSingleIdDocument",
  async ({ file }, { getState, dispatch, rejectWithValue }) => {
    const state = getState().marriageForm;
    const mosqueGlideId = state.mosqueGlideId;
    const userEmail = state.userEmail;

    const formData = new FormData();
    formData.append("mosque_glide_id", mosqueGlideId);
    formData.append("user_email", userEmail);
    formData.append("resource_name", MARRIAGE_RESOURCE_NAME);
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${apiUrl}/s3/upload_resource_file`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              );
              dispatch(setSingleFileUploadProgress(progress));
            }
          },
        },
      );

      if (response.status === 201) {
        return { fileUrl: response.data.file_url };
      } else {
        return rejectWithValue("Failed to upload file");
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error || "Upload failed";
      return rejectWithValue(errorMessage);
    }
  },
);

// Delete Single ID Document
export const deleteSingleIdDocument = createAsyncThunk(
  "marriageForm/deleteSingleIdDocument",
  async (_, { getState, rejectWithValue }) => {
    const state = getState().marriageForm;
    const filename = state.uploadSingleFile.singleFileName;
    const mosqueGlideId = state.mosqueGlideId;
    const userEmail = state.userEmail;

    if (!filename) {
      return rejectWithValue("No file to delete");
    }

    const formData = new FormData();
    formData.append("mosque_glide_id", mosqueGlideId);
    formData.append("user_email", userEmail);
    formData.append("resource_name", MARRIAGE_RESOURCE_NAME);
    formData.append("filename", filename);

    try {
      const response = await axios.delete(`${apiUrl}/s3/delete_resource_file`, {
        data: formData,
      });

      if (response.status === 200) {
        return { fileKey: response.data.file_key };
      } else {
        return rejectWithValue("Failed to delete file");
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error || "Delete failed";
      return rejectWithValue(errorMessage);
    }
  },
);

// Upload Multiple ID Documents
export const uploadMultipleIdDocuments = createAsyncThunk(
  "marriageForm/uploadMultipleIdDocuments",
  async ({ newFiles }, { getState, dispatch, rejectWithValue }) => {
    for (const file of newFiles) {
      const fileName = file.name;
      dispatch(addMultipleFile({ fileName }));

      const state = getState().marriageForm;
      const mosqueGlideId = state.mosqueGlideId;
      const userEmail = state.userEmail;

      const formData = new FormData();
      formData.append("mosque_glide_id", mosqueGlideId);
      formData.append("user_email", userEmail);
      formData.append("resource_name", MARRIAGE_RESOURCE_NAME);
      formData.append("file", file);

      try {
        const response = await axios.post(
          `${apiUrl}/s3/upload_resource_file`,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              if (progressEvent.total) {
                const progress = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total,
                );
                dispatch(updateMultipleFileProgress({ fileName, progress }));
              }
            },
          },
        );

        if (response.status === 201) {
          dispatch(
            markMultipleFileUploaded({
              fileName,
              fileUrl: response.data.file_url,
            }),
          );
        } else {
          dispatch(
            markMultipleFileError({ fileName, error: "Failed to upload file" }),
          );
          return rejectWithValue("Failed to upload file");
        }
      } catch (error) {
        const errorMessage = error.response?.data?.error || "Upload failed";
        dispatch(markMultipleFileError({ fileName, error: errorMessage }));
        return rejectWithValue(errorMessage);
      }
    }

    return { success: true };
  },
);

// Delete All Multiple ID Documents
export const deleteAllMultipleIdDocuments = createAsyncThunk(
  "marriageForm/deleteAllMultipleIdDocuments",
  async (_, { getState, dispatch, rejectWithValue }) => {
    const state = getState().marriageForm;
    const files = state.uploadMultipleFiles.files;
    const mosqueGlideId = state.mosqueGlideId;
    const userEmail = state.userEmail;

    if (files.length === 0) {
      return rejectWithValue("No files to delete");
    }

    dispatch(markAllMultipleFilesDeleting());

    for (const { fileName } of files) {
      const formData = new FormData();
      formData.append("mosque_glide_id", mosqueGlideId);
      formData.append("user_email", userEmail);
      formData.append("resource_name", MARRIAGE_RESOURCE_NAME);
      formData.append("filename", fileName);

      try {
        const response = await axios.delete(
          `${apiUrl}/s3/delete_resource_file`,
          { data: formData },
        );

        if (response.status !== 200) {
          dispatch(
            markMultipleFileError({ fileName, error: "Failed to delete file" }),
          );
          return rejectWithValue("Failed to delete one or more files");
        }
      } catch (error) {
        const errorMessage = error.response?.data?.error || "Delete failed";
        dispatch(markMultipleFileError({ fileName, error: errorMessage }));
        return rejectWithValue(errorMessage);
      }
    }

    dispatch(clearMultipleFilesUpload());
    return { success: true };
  },
);

// Create Marriage Booking
export const createMarriageBooking = createAsyncThunk(
  "marriageForm/createMarriageBooking",
  async (_, { getState, dispatch, rejectWithValue }) => {
    dispatch(setIsSubmitting(true));
    dispatch(setSubmissionError(null));
    dispatch(setShowThankYou(false));

    const state = getState().marriageForm;
    const formData = state.formData;
    const mosqueGlideId = state.mosqueGlideId;
    const userEmail = state.userEmail;

    const payload = {
      user_email: userEmail,
      mosque_glide_id: mosqueGlideId,
      booking_slot_ids: formData.selectedTimeSlots.map(
        (slot) => slot.booking_slot_id,
      ),
      groom_name: formData.groomName || "",
      groom_phone_number: formData.groomPhoneNumber || "",
      additional_information: formData.additionalInformation || "",
      id_document: formData.idDocument || "",
      supporting_documents: formData.additionalDocuments || [],
    };

    try {
      const response = await axios.post(`${apiUrl}/bookings/marriage`, payload);
      if (response.status === 201) {
        dispatch(setShowThankYou(true));
        return response.data;
      } else {
        return rejectWithValue("An unexpected error occurred.");
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || "Failed to create booking.";
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setIsSubmitting(false));
    }
  },
);
