import React from "react";
import { Label } from "components/shadcn/Label";
import { Input } from "components/shadcn/Input";
import { Textarea } from "components/shadcn/Textarea";

function Stage1BasicInfo({ formData, errors, labels, onChange, onBlur }) {
  return (
    <>
      <div className="mb-4">
        <Label htmlFor="name">{labels.name}</Label>
        <Input
          type="text"
          name="name"
          id="name"
          value={formData.name || ""}
          onChange={onChange}
          onBlur={onBlur}
          className="mt-1 block w-full"
          placeholder={labels.namePlaceholder}
        />
        {errors.name && (
          <div className="text-red-500 text-sm pt-2">{errors.name}</div>
        )}
      </div>

      <div className="mb-4">
        <Label htmlFor="phoneNumber">{labels.phoneNumber}</Label>
        <Input
          type="tel"
          name="phoneNumber"
          id="phoneNumber"
          value={formData.phoneNumber || ""}
          onChange={onChange}
          onBlur={onBlur}
          className="mt-1 block w-full"
          placeholder={labels.phoneNumberPlaceholder}
        />
        {errors.phoneNumber && (
          <div className="text-red-500 text-sm pt-2">{errors.phoneNumber}</div>
        )}
      </div>

      <div className="mb-4">
        <Label htmlFor="purposeOfHire">{labels.purposeOfHire}</Label>
        <Input
          type="text"
          name="purposeOfHire"
          id="purposeOfHire"
          value={formData.purposeOfHire || ""}
          onChange={onChange}
          onBlur={onBlur}
          className="mt-1 block w-full"
          placeholder={labels.purposeOfHirePlaceholder}
        />
        {errors.purposeOfHire && (
          <div className="text-red-500 text-sm pt-2">
            {errors.purposeOfHire}
          </div>
        )}
      </div>

      <div className="mb-4">
        <Label htmlFor="numberOfPeople">{labels.numberOfPeople}</Label>
        <Input
          type="number"
          id="numberOfPeople"
          name="numberOfPeople"
          value={formData.numberOfPeople || 0}
          onChange={onChange}
          onBlur={onBlur}
          className="w-24 mt-1"
          placeholder={labels.numberOfPeoplePlaceholder}
          min="0"
        />
        {errors.numberOfPeople && (
          <div className="text-red-500 text-sm pt-2">
            {errors.numberOfPeople}
          </div>
        )}
      </div>

      <div className="mb-4">
        <Label htmlFor="additionalInformation">
          {labels.additionalInformation}
        </Label>
        <Textarea
          name="additionalInformation"
          id="additionalInformation"
          value={formData.additionalInformation || ""}
          onChange={onChange}
          className="mt-1 block w-full"
          placeholder={labels.additionalInformationPlaceholder}
        />
      </div>
    </>
  );
}

export default Stage1BasicInfo;
