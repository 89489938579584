import React from "react";
import { Label } from "components/shadcn/Label";
import { Checkbox } from "components/shadcn/Checkbox";
import { Button } from "components/shadcn/Button";

const TermsAndConditions = ({
  agreedToTerms,
  onAgreedChange,
  error,
  labels,
}) => {
  return (
    <div className="mb-4">
      <div className="mb-4 flex items-center space-x-2">
        <div className="px-2">
          <Checkbox
            id="agreedToTerms"
            name="agreedToTerms"
            checked={!!agreedToTerms}
            onCheckedChange={(checked) => onAgreedChange(!!checked)}
          />
        </div>
        <Label htmlFor="agreedToTerms">{labels.agreeToTerms}</Label>
        <Button
          type="button"
          variant="outline"
          size="sm"
          onClick={() => console.log("Open T&C PDF")}
        >
          {labels.viewTerms}
        </Button>
      </div>
      {error && <div className="text-red-500 text-sm">{error}</div>}
    </div>
  );
};

export default TermsAndConditions;
