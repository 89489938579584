import React from "react";
import { Card, CardContent } from "components/shadcn/Card";
import ServiceItem from "components/attendee/ServiceItem";
import graveyardIcon from "assets/services/graveyard_icon.png";
import quranIcon from "assets/services/quran_icon.png";
import islamicSchoolIcon from "assets/services/islamic_school_icon.png";
import counsellingIcon from "assets/services/islamic_counselling_icon.png";
import libraryIcon from "assets/services/library_icon.png";
import healthIcon from "assets/services/health_icon.png";
import languageIcon from "assets/services/language_icon.png";
import askImaamIcon from "assets/services/ask_imaam_icon.png";
import hallBookingIcon from "assets/services/hall_booking_icon.png";

const Services = () => {
  const services = [
    { name: "Funeral", icon: graveyardIcon, path: "funeral" },
    { name: "Qur'an", icon: quranIcon, path: "quranic-studies" },
    { name: "IslamicSchool", icon: islamicSchoolIcon, path: "islamic-school" },
    { name: "Counselling", icon: counsellingIcon, path: "counselling" },
    { name: "Library", icon: libraryIcon, path: "library" },
    { name: "Health", icon: healthIcon, path: "health" },
    { name: "Language", icon: languageIcon, path: "language" },
    { name: "Ask Imaam", icon: askImaamIcon, path: "ask-imaam" },
    { name: "Hall Booking", icon: hallBookingIcon, path: "hall-booking" },
  ];

  return (
    <div className="container mx-auto p-4 sm:p-6">
      <Card className="bg-card">
        <CardContent className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 p-4 sm:p-6">
          {services.map((service, index) => (
            <ServiceItem key={index} service={service} />
          ))}
        </CardContent>
      </Card>
    </div>
  );
};

export default Services;
