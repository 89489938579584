import React from "react";
import {
  Home,
  Settings,
  Grid,
  Newspaper,
  Calendar,
  Bookmark,
  GalleryVerticalEnd,
  AudioWaveform,
  Command,
} from "lucide-react";

import { NavMain } from "./nav_main";
import { NavProjects } from "./nav_projects";
import { NavUser } from "./nav_user";
import { TeamSwitcher } from "./team_switcher";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
} from "components/shadcn/Sidebar";

// This is sample data.
const data = {
  user: {
    name: "shadcn",
    email: "m@example.com",
    avatar: "/avatars/shadcn.jpg",
  },
  teams: [
    {
      name: "Acme Inc",
      logo: GalleryVerticalEnd,
      plan: "Enterprise",
    },
    {
      name: "Acme Corp.",
      logo: AudioWaveform,
      plan: "Startup",
    },
    {
      name: "Evil Corp.",
      logo: Command,
      plan: "Free",
    },
  ],
  navMain: [
    {
      title: "Home",
      url: "/#/dashboard/attendee/home",
      icon: Home,
      isActive: true,
    },
    {
      title: "News",
      url: "/#/dashboard/attendee/news",
      icon: Newspaper,
    },
    {
      title: "Events",
      url: "/#/dashboard/attendee/events",
      icon: Calendar,
    },
    {
      title: "Subscription",
      url: "/#/dashboard/attendee/subscription",
      icon: Bookmark,
    },
    {
      title: "Services",
      url: "/#/dashboard/attendee/services",
      icon: Grid,
      items: [
        {
          title: "Marriage",
          url: "/#/dashboard/attendee/services/marriage",
        },
        {
          title: "Funeral",
          url: "/#/dashboard/attendee/services/funeral",
        },
        {
          title: "Quranic Studies",
          url: "/#/dashboard/attendee/services/quranic-studies",
        },
        {
          title: "Islamic School",
          url: "/#/dashboard/attendee/services/islamic-school",
        },
        {
          title: "Islamic Counselling",
          url: "/#/dashboard/attendee/services/counselling",
        },
        {
          title: "Library",
          url: "/#/dashboard/attendee/services/library",
        },
        {
          title: "Health",
          url: "/#/dashboard/attendee/services/health",
        },
        {
          title: "Language",
          url: "/#/dashboard/attendee/services/language",
        },
        {
          title: "Ask Imaam",
          url: "/#/dashboard/attendee/services/ask-imaam",
        },
        {
          title: "Hall Booking",
          url: "/#/dashboard/attendee/services/hall-booking",
        },
      ],
    },
    {
      title: "Settings",
      url: "/#/dashboard/attendee/settings",
      icon: Settings,
      items: [
        {
          title: "General",
          url: "/#/dashboard/attendee/settings",
        },
        {
          title: "Profile",
          url: "/#/dashboard/attendee/settings/profile",
        },
      ],
    },
  ],
  projects: [
    {
      name: "Recent Services",
      url: "#",
      icon: Bookmark,
    },
    {
      name: "Upcoming Events",
      url: "#",
      icon: Calendar,
    },
    {
      name: "Latest News",
      url: "#",
      icon: Newspaper,
    },
  ],
};
export function AppSidebar(props) {
  return (
    <Sidebar collapsible="icon" {...props}>
      <SidebarHeader>
        <TeamSwitcher teams={data.teams} />
      </SidebarHeader>
      <SidebarContent>
        <NavMain items={data.navMain} />
        <NavProjects projects={data.projects} />
      </SidebarContent>
      <SidebarFooter>
        <NavUser user={data.user} />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
