import axios from "axios";

const API_URL =
  "https://de73ab92-b4cd-4138-b8d1-e06835111c6c-00-qeiogag3ocfm.picard.replit.dev/v1";

// ------------------
// Booking Slot Endpoints
// ------------------

// Single booking slot: non-space
const createSingleBookingSlotNonSpace = async (payload) => {
  const response = await axios.post(
    `${API_URL}/booking_slots/create_single_booking_slot_non_space`,
    {
      resource_id: payload.resource_id,
      date: payload.date,
      start_time: payload.start_time,
      end_time: payload.end_time,
      status: payload.status,
    },
  );
  return response.data;
};

// Single booking slot: space
const createSingleBookingSlotSpace = async (payload) => {
  const response = await axios.post(
    `${API_URL}/booking_slots/create_single_booking_slot_space`,
    {
      resource_id: payload.resource_id,
      space_ids: payload.space_ids,
      date: payload.date,
      start_time: payload.start_time,
      end_time: payload.end_time,
      status: payload.status,
    },
  );
  return response.data;
};

// Recurring booking slots: non-space
const createRecurringBookingSlotsNonSpace = async (payload) => {
  const response = await axios.post(
    `${API_URL}/booking_slots/create_recurring_booking_slots_non_space`,
    {
      resource_id: payload.resource_id,
      start_date: payload.start_date,
      start_time: payload.start_time,
      end_time: payload.end_time,
      weeks: payload.weeks,
      status: payload.status,
    },
  );
  return response.data;
};

// Recurring booking slots: space
const createRecurringBookingSlotsSpace = async (payload) => {
  const response = await axios.post(
    `${API_URL}/booking_slots/create_recurring_booking_slots_space`,
    {
      resource_id: payload.resource_id,
      space_ids: payload.space_ids,
      start_date: payload.start_date,
      start_time: payload.start_time,
      end_time: payload.end_time,
      weeks: payload.weeks,
      status: payload.status,
    },
  );
  return response.data;
};

const updateBookingSlot = async (bookingSlotId, updateFields) => {
  const response = await axios.put(
    `${API_URL}/booking_slots/update_booking_slot`,
    {
      booking_slot_id: bookingSlotId,
      update_fields: updateFields,
    },
  );
  return response.data;
};

const deleteBookingSlot = async (bookingSlotId) => {
  const response = await axios.delete(
    `${API_URL}/booking_slots/delete_booking_slot`,
    {
      data: { booking_slot_id: bookingSlotId },
    },
  );
  return response.data;
};

// Get open booking slots
const getOpenBookingSlots = async (formData) => {
  const response = await axios.post(
    `${API_URL}/booking_slots/get_open_booking_slots`,
    formData,
  );
  return response.data;
};

// ------------------
// Booking Endpoints
// ------------------

const getAllBookings = async () => {
  const response = await axios.get(`${API_URL}/bookings/get_all_bookings`);
  return response.data;
};

// ------------------
// Resource & Space Endpoints
// ------------------

// Get resources
const getResources = async (mosqueId, mosqueGlideId) => {
  const response = await axios.post(`${API_URL}/resources/get_resources`, {
    mosque_id: mosqueId,
    mosque_glide_id: mosqueGlideId,
  });
  if (response.data.resources) {
    return response.data.resources;
  }
  return [];
};

// Update resource
const updateResource = async (resourceId, name, description) => {
  const response = await axios.put(`${API_URL}/resources/update_resource`, {
    resource_id: resourceId,
    name,
    description,
  });
  return response.data;
};

// Update space
const updateSpace = async (spaceId, name, description) => {
  const response = await axios.put(`${API_URL}/spaces/update_space`, {
    space_id: spaceId,
    name,
    description,
  });
  return response.data;
};

const adminAPI = {
  // Booking slots
  createSingleBookingSlotNonSpace,
  createSingleBookingSlotSpace,
  createRecurringBookingSlotsNonSpace,
  createRecurringBookingSlotsSpace,
  updateBookingSlot,
  deleteBookingSlot,
  getOpenBookingSlots,

  // Bookings
  getAllBookings,

  // Resources & spaces
  getResources,
  updateResource,
  updateSpace,
};

export default adminAPI;
