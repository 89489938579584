import axios from "axios";

const API_BASE_URL = "https://api.aladhan.com/v1";

export const getPrayerTimes = async (year, month, city, country, method) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/calendarByCity/${year}/${month}`,
      {
        params: {
          city,
          country,
          method,
        },
      },
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching prayer times:", error);
    return [];
  }
};
