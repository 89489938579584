import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Button } from "components/shadcn/Button";
import {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardContent,
} from "components/shadcn/Card";

import ThankYou from "./ThankYou";
import Stage1BasicInfo from "./stage1-basic-info/Stage1BasicInfo";
import Stage2SpacesAndCalendar from "./stage2-spaces-and-calendar/Stage2SpacesAndCalendar";
import Stage3Overview from "./stage3-overview/Stage3Overview";
import Stage4Payment from "./stage4-payment/Stage4Payment";

import {
  setMosqueGlideIdAndUserEmail,
  setShowThankYou,
} from "store/forms/spaces-form/spacesFormSlice";
import { fetchOpenBookingSlots } from "store/forms/spaces-form/thunks";

import { SPACES_FORM_LABELS } from "constants/formsConstants";

import useSpacesFormHandlers from "./useSpacesFormHandlers";

function SpacesForm() {
  const dispatch = useDispatch();

  const {
    formData,
    submissionError,
    showThankYou,
    spaceIds,
    currentMonth,
    currentStep,
    clientSecret,
  } = useSelector((state) => state.spacesForm);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mosqueGlideId = searchParams.get("mosque_glide_id");
  const userEmail = searchParams.get("user_email");
  const lang = searchParams.get("lang") || "en";

  const labels = SPACES_FORM_LABELS[lang];
  const isRTL = lang === "ku";

  const [errors, setErrors] = useState({});

  const {
    handleChange,
    handleBlur,
    handleSpaceChange,
    handleTimeSlotChange,
    handlePaymentTypeChange,
    handleAgreedChange,
    handleNext,
    handlePrev,
  } = useSpacesFormHandlers({
    dispatch,
    formData,
    spaceIds,
    currentStep,
    errors,
    setErrors,
    lang,
  });

  useEffect(() => {
    if (mosqueGlideId && userEmail) {
      dispatch(setMosqueGlideIdAndUserEmail({ mosqueGlideId, userEmail }));
    }
  }, [dispatch, mosqueGlideId, userEmail]);

  useEffect(() => {
    if (currentMonth) {
      dispatch(fetchOpenBookingSlots({ startDate: currentMonth }));
    }
  }, [spaceIds, currentMonth, dispatch]);

  const renderStep = () => {
    if (currentStep === 1) {
      return (
        <Stage1BasicInfo
          formData={formData}
          errors={errors}
          labels={labels}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      );
    }

    if (currentStep === 2) {
      return (
        <Stage2SpacesAndCalendar
          errors={errors}
          spaceIds={spaceIds}
          lang={lang}
          isRTL={isRTL}
          handleSpaceChange={handleSpaceChange}
          handleTimeSlotChange={handleTimeSlotChange}
        />
      );
    }

    if (currentStep === 3) {
      return (
        <Stage3Overview
          formData={formData}
          spaceIds={spaceIds}
          errors={errors}
          labels={labels}
          handlePaymentTypeChange={handlePaymentTypeChange}
          handleAgreedChange={handleAgreedChange}
        />
      );
    }

    if (currentStep === 4 && clientSecret) {
      return (
        <Stage4Payment
          clientSecret={clientSecret}
          onSuccess={() => dispatch(setShowThankYou(true))}
        />
      );
    }

    return null;
  };

  return (
    <div className="px-4 py-8" dir={isRTL ? "rtl" : "ltr"}>
      {showThankYou && <ThankYou lang={lang} />}
      {!showThankYou && (
        <Card className="w-full max-w-4xl mx-auto">
          <CardHeader className="bg-gray-100">
            <CardTitle className="text-2xl font-bold">
              {labels.steps[currentStep - 1]}
            </CardTitle>
          </CardHeader>
          <CardContent className="p-4">
            {renderStep()}
            {submissionError && (
              <div className="mb-4 text-red-500">{submissionError}</div>
            )}
          </CardContent>
          {!showThankYou && currentStep < 4 && (
            <CardFooter className="bg-gray-100 flex flex-row justify-between items-center py-4">
              {currentStep > 1 ? (
                <Button type="button" variant="outline" onClick={handlePrev}>
                  {labels.previous}
                </Button>
              ) : (
                <span className="invisible">Placeholder</span>
              )}
              {currentStep === 3 ? (
                <Button type="button" onClick={handleNext}>
                  {formData.paymentType === "cash"
                    ? "Book"
                    : labels.proceedToPayment}
                </Button>
              ) : currentStep < 3 ? (
                <Button type="button" onClick={handleNext}>
                  {labels.next}
                </Button>
              ) : null}
            </CardFooter>
          )}
        </Card>
      )}
    </div>
  );
}

export default SpacesForm;
