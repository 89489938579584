import React, { useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Button } from "components/shadcn/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  goToPreviousMonth,
  goToNextMonth,
  setSelectedDay,
  setSelectedTimeSlot,
} from "store/forms/marriage-form/marriageFormSlice";
import { fetchOpenBookingSlots } from "store/forms/marriage-form/thunks";
import {
  getDaysInMonth,
  getFormattedDay,
  getFormattedMonth,
  getFormattedTimeRange,
} from "utils/dateUtils";
import { DAYS_OF_WEEK } from "constants/formsConstants";

function Calendar() {
  const dispatch = useDispatch();

  const currentMonth = useSelector((state) => state.marriageForm.currentMonth);
  const currentDaysOpenThisMonth = useSelector(
    (state) => state.marriageForm.currentDaysOpenThisMonth,
  );
  const selectedDay = useSelector((state) => state.marriageForm.selectedDay);
  const timeSlots = useSelector((state) => state.marriageForm.timeSlots);
  const selectedTimeSlots = useSelector(
    (state) => state.marriageForm.formData.selectedTimeSlots,
  );
  const isLoadingSlots = useSelector(
    (state) => state.marriageForm.isLoadingSlots,
  );

  const days = getDaysInMonth(currentMonth);

  const handleDayClick = (day) => {
    const formattedDay = getFormattedDay(currentMonth, day);
    if (currentDaysOpenThisMonth[formattedDay]) {
      dispatch(setSelectedDay(formattedDay));
    }
  };

  const handleTimeSlotClick = (slot) => {
    dispatch(setSelectedTimeSlot(slot.details));
  };

  const renderDaySkeletons = () => {
    return days.map((day, index) => (
      <div
        key={index}
        className="flex items-center justify-center w-10 h-10 rounded-md bg-gray-200 animate-pulse"
      ></div>
    ));
  };

  const renderDays = () => {
    return days.map((day, index) => {
      const formattedDay = day ? getFormattedDay(currentMonth, day) : null;
      const openDay = formattedDay
        ? Boolean(currentDaysOpenThisMonth[formattedDay])
        : false;
      const isSelected = selectedDay === formattedDay;

      return (
        <div
          key={index}
          onClick={() => day && openDay && handleDayClick(day)}
          className={`flex items-center justify-center w-10 h-10 rounded-md ${
            day
              ? openDay
                ? isSelected
                  ? "bg-primary text-primary-foreground cursor-pointer"
                  : "bg-primary-light text-primary-foreground hover:bg-primary/90 cursor-pointer"
                : "bg-disabled text-disabled-foreground cursor-not-allowed"
              : ""
          }`}
        >
          {day || ""}
        </div>
      );
    });
  };

  const renderTimeSlots = () => {
    return timeSlots.map((slot, index) => (
      <Button
        key={index}
        type="button"
        onClick={() => handleTimeSlotClick(slot)}
        className={`w-full my-2 text-sm ${
          selectedTimeSlots?.[0]?.start_datetime === slot.start
            ? "bg-primary text-primary-foreground"
            : "bg-muted text-muted-foreground hover:bg-muted/90"
        }`}
      >
        {getFormattedTimeRange(slot.start, slot.end)}
      </Button>
    ));
  };

  useEffect(() => {
    if (currentMonth) {
      dispatch(fetchOpenBookingSlots({ startDate: currentMonth }));
    }
  }, [currentMonth, dispatch]);

  return (
    <div className="max-w-md mx-auto">
      {/* HEADER */}
      <div className="flex items-center justify-between mb-4">
        <Button
          type="button"
          variant="ghost"
          onClick={() => dispatch(goToPreviousMonth())}
        >
          <ChevronLeft className="w-5 h-5 text-gray-500 hover:text-gray-700" />
        </Button>
        <h2 className="text-lg font-medium">
          {getFormattedMonth(currentMonth)}
        </h2>
        <Button
          type="button"
          variant="ghost"
          onClick={() => dispatch(goToNextMonth())}
        >
          <ChevronRight className="w-5 h-5 text-gray-500 hover:text-gray-700" />
        </Button>
      </div>

      {/* DAYS GRID */}
      <div className="grid grid-cols-7 gap-1 justify-items-center">
        {DAYS_OF_WEEK.map((day) => (
          <div key={day} className="text-center font-medium text-gray-600">
            {day}
          </div>
        ))}
        {isLoadingSlots ? renderDaySkeletons() : renderDays()}
      </div>

      {/* TIME SLOTS */}
      {selectedDay && !isLoadingSlots && (
        <div className="mt-4">
          <h3 className="text-md font-semibold">
            Time Slots for {selectedDay}
          </h3>
          <div className="mt-2">{renderTimeSlots()}</div>
        </div>
      )}
    </div>
  );
}

export default Calendar;
