import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "components/shadcn/Card";
import { Button } from "components/shadcn/Button";
import { Separator } from "components/shadcn/Separator";
import { ChevronRight } from "lucide-react";
import ServiceItem from "components/attendee/ServiceItem";
import PrayerTimes from "components/attendee/PrayerTimes";
import AskImaam from "pages/dashboard/attendee/services/AskImaam";

// Import icons
import marriageIcon from "assets/services/marriage_icon.png";
import graveyardIcon from "assets/services/graveyard_icon.png";
import quranIcon from "assets/services/quran_icon.png";
import islamicSchoolIcon from "assets/services/islamic_school_icon.png";
import counsellingIcon from "assets/services/islamic_counselling_icon.png";
import libraryIcon from "assets/services/library_icon.png";
import healthIcon from "assets/services/health_icon.png";
import languageIcon from "assets/services/language_icon.png";
import askImaamIcon from "assets/services/ask_imaam_icon.png";

const AttendeeHome = () => {
  const navigate = useNavigate();

  const services = [
    { name: "Marriage", icon: marriageIcon, path: "marriage" },
    { name: "Funeral", icon: graveyardIcon, path: "funeral" },
    { name: "Qur'an", icon: quranIcon, path: "tahfizul-quran" },
    { name: "Islamic School", icon: islamicSchoolIcon, path: "islamic-school" },
    { name: "Counselling", icon: counsellingIcon, path: "counselling" },
    { name: "Library", icon: libraryIcon, path: "library" },
    { name: "Health", icon: healthIcon, path: "health" },
    { name: "Language", icon: languageIcon, path: "language" },
    { name: "Ask Imaam", icon: askImaamIcon, path: "ask-imaam" },
  ];

  const handleViewAllServices = () => {
    navigate("/dashboard/attendee/services");
  };

  return (
    <div className="container mx-auto p-6 space-y-6">
      <Card className="w-full bg-card">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
          <CardTitle className="text-xl font-bold">Services</CardTitle>
          <Button
            variant="ghost"
            className="flex items-center hover:bg-accent"
            onClick={handleViewAllServices}
          >
            See all
            <ChevronRight className="ml-2 h-4 w-4" />
          </Button>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
            {services.map((service, index) => (
              <ServiceItem key={index} service={service} />
            ))}
          </div>
        </CardContent>
      </Card>

      <Separator className="my-6" />

      <Card className="w-full bg-card">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
          <CardTitle className="text-xl font-bold">Prayer Times</CardTitle>
          <Button
            variant="ghost"
            className="flex items-center hover:bg-accent"
            onClick={handleViewAllServices}
          >
            Call Imaam
            <ChevronRight className="ml-2 h-4 w-4" />
          </Button>
        </CardHeader>
        <CardContent>
          <PrayerTimes />
        </CardContent>
      </Card>

      <Separator className="my-6" />

      <Card className="w-full bg-card">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
          <CardTitle className="text-xl font-bold">Ask your Imaam</CardTitle>
          <Button
            variant="ghost"
            className="flex items-center hover:bg-accent"
            onClick={handleViewAllServices}
          >
            Call Imaam
            <ChevronRight className="ml-2 h-4 w-4" />
          </Button>
        </CardHeader>
        <CardContent>
          <AskImaam />
        </CardContent>
      </Card>
    </div>
  );
};

export default AttendeeHome;
