import React, { useState } from "react";
import {
  HiOutlineBell,
  HiOutlineMoon,
  HiOutlineLanguage,
  HiOutlineQuestionMarkCircle,
  HiOutlineArrowRightOnRectangle,
} from "react-icons/hi2";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "components/shadcn/Card";
import { Switch } from "components/shadcn/Switch";
import { Button } from "components/shadcn/Button";
import { Separator } from "components/shadcn/Separator";

const Settings = () => {
  const [notifications, setNotifications] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [language] = useState("English");

  const toggleNotifications = () => {
    setNotifications(!notifications);
    // TODO: Implement notification toggle logic
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    // TODO: Implement dark mode toggle logic
  };

  const changeLanguage = () => {
    // TODO: Implement language change logic
    alert("Language change functionality to be implemented");
  };

  const openHelp = () => {
    // TODO: Implement help/support logic
    alert("Help and support functionality to be implemented");
  };

  const handleLogout = () => {
    // TODO: Implement logout logic
    alert("Logout functionality to be implemented");
  };

  return (
    <Card className="max-w-2xl mx-auto">
      <CardHeader>
        <CardTitle>Settings</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="flex items-center justify-between py-2">
          <div className="flex items-center space-x-4">
            <HiOutlineBell className="h-5 w-5" />
            <span>Notifications</span>
          </div>
          <Switch
            checked={notifications}
            onCheckedChange={toggleNotifications}
          />
        </div>

        <Separator />

        <div className="flex items-center justify-between py-2">
          <div className="flex items-center space-x-4">
            <HiOutlineMoon className="h-5 w-5" />
            <span>Dark Mode</span>
          </div>
          <Switch checked={darkMode} onCheckedChange={toggleDarkMode} />
        </div>

        <Separator />

        <Button
          variant="ghost"
          className="w-full flex items-center justify-between py-2"
          onClick={changeLanguage}
        >
          <div className="flex items-center space-x-4">
            <HiOutlineLanguage className="h-5 w-5" />
            <span>Language</span>
          </div>
          <span>{language}</span>
        </Button>

        <Separator />

        <Button
          variant="ghost"
          className="w-full flex items-center justify-between py-2"
          onClick={openHelp}
        >
          <div className="flex items-center space-x-4">
            <HiOutlineQuestionMarkCircle className="h-5 w-5" />
            <span>Help & Support</span>
          </div>
        </Button>

        <Separator />

        <Button
          variant="ghost"
          className="w-full flex items-center justify-between py-2 text-destructive"
          onClick={handleLogout}
        >
          <div className="flex items-center space-x-4">
            <HiOutlineArrowRightOnRectangle className="h-5 w-5" />
            <span>Logout</span>
          </div>
        </Button>
      </CardContent>
    </Card>
  );
};

export default Settings;
