export const PURPOSE = "spaces";
export const DAYS_OF_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB
export const ACCEPTED_FILE_TYPES = [
  "application/pdf",
  "image/jpeg",
  "image/png",
];
export const MARRIAGE_RESOURCE_ID = "22222222-2222-2222-2222-222222222222";
export const MARRIAGE_SPACE_IDS = [];
export const MARRIAGE_RESOURCE_NAME = "marriage";

export const SPACES_RESOURCE_ID = "33333333-3333-3333-3333-333333333333";
export const SPACES_RESOURCE_NAME = "spaces";

export const SPACES_OPTIONS = [
  {
    id: "55555555-5555-5555-5555-555555555555",
    name: {
      en: "Small Hall",
      ku: "هۆڵی بچووك",
    },
    cost: 100,
    tooltip: {
      en: "A cozy, intimate space suitable for small gatherings.",
      ku: "بوشاییەكی نێوانی و دانیشتنی بچووك، گونجاو بۆ كۆبونەوەكانی بچووك.",
    },
  },
  {
    id: "77777777-7777-7777-7777-777777777777",
    name: {
      en: "Meeting Room",
      ku: "ژووری كۆبوونەوە",
    },
    cost: 25,
    tooltip: {
      en: "A private room perfect for meetings or workshops.",
      ku: "ژووری تایبەت، گونجاو بۆ كۆبوونەوەكان یان وۆركشۆپەكان.",
    },
  },
  {
    id: "66666666-6666-6666-6666-666666666666",
    name: {
      en: "Kitchen",
      ku: "ئاشپزخانوو",
    },
    cost: 100,
    tooltip: {
      en: "A fully equipped kitchen for catering and cooking sessions.",
      ku: "ئاشپزخانەیەك بە ئامێرە تام، گونجاو بۆ ئامادەكردنی خواردن و خولەكانی پێكهات.",
    },
  },
  {
    id: "44444444-4444-4444-4444-444444444444",
    name: {
      en: "Large Hall",
      ku: "هۆڵی گەورە",
    },
    cost: 200,
    tooltip: {
      en: "Spacious hall suitable for large events and ceremonies.",
      ku: "هۆڵی گەورە، گونجاو بۆ رێكخستنی بێجگەكان و ئاهەنگە گەورەكان.",
    },
  },
];

export const VALIDATION_ERRORS = {
  en: {
    name: "Please enter your full name (at least 5 characters).",
    phoneNumber:
      "Please enter a valid UK phone number. E.g. +4474455123123 or 07455123123 (spaces allowed)",
    purposeOfHire:
      "Please give a more descriptive reason (at least 3 characters).",
    numberOfPeople:
      "Please specify the number of people attending (must be greater than 0).",
    spaceIds: "Please select at least one space.",
    selectedTimeSlots:
      "Please select an open timeslot for your required spaces.",
    paymentType:
      "Please choose whether you will pay the full amount or only half as a deposit.",
    agreedToTerms:
      "Please read the terms and conditions and agree with them to proceed.",
  },
  ku: {
    name: "تکایە ناوی تەواوت بنووسە (کەمتر لە ٥ پیت نەبێت).",
    phoneNumber:
      "تکایە ژمارەی تەلەفۆنی بەشێوەیەکی دروست بنووسە. وەکو +4474455123123 یان 07455123123",
    purposeOfHire: "تکایە هۆکارێکی زۆرتر بەڕێوەبە (کەمتر لە ٣ پیت نەبێت).",
    numberOfPeople: "تکایە ژمارەی کەسان بنووسە (دەبێت لە ٠ زیادتر بێت).",
    spaceIds: "تکایە بەلایەک بوشایی هەڵبژێرە.",
    selectedTimeSlots: "تکایە کاتێکێکی بەتاڵ بۆ بوشاییەکانت هەڵبژێرە.",
    paymentType: "تکایە دیاری بکە ئایا کۆی نرخی داوە یان بە نیمپارە دەدا.",
    agreedToTerms: "تکایە مەرجەکان بخوێنەوە و بۆ بەردەوامبوون لێیان رازی بێت.",
  },
};

export const MARRIAGE_FORM_LABELS = {
  en: {
    groomName: "Groom Name *",
    groomNamePlaceholder: "Mohammad Ahmed",
    groomPhoneNumber: "Groom Phone Number *",
    groomPhoneNumberPlaceholder: "07912 345 678",
    additionalInformation: "Additional Information (Optional)",
    additionalInformationPlaceholder: "Any extra details...",
    deleting: "Deleting...",
    uploadIdDocument: "Upload ID Document",
    uploadIdDocumentNote: "(PDF, JPG or PNG and Under 10MB)",
    acceptedIdDocuments: "Accepted ID Documents:",
    birthCertificate: "Birth Certificate",
    drivingLicense: "Driving License",
    passport: "Passport",
    otherId: "Other government-issued ID",
    deletingAllFiles: "Deleting all files...",
    uploadSupportingDocuments: "Upload Supporting Documents",
    uploadSupportingDocumentsNote:
      "(Up to 3 files, PDF, JPG, or PNG under 10MB each)",
    uploaded: "Uploaded",
    supportingDocuments: "Supporting Documents:",
    referenceLetters: "Reference Letters",
    certificates: "Certificates",
    proofOfIncome: "Proof of Income",
    otherRelevantDocuments: "Other relevant documents",
    thankYouTitle: "Thank You!",
    thankYouMessage: "Your booking was created successfully.",
  },
  ku: {
    groomName: "* ناوی داواکار",
    groomNamePlaceholder: "محمد احمد",
    groomPhoneNumber: "* ژمارەی تەلەفون",
    groomPhoneNumberPlaceholder: "٠٧٩١٢٣٤٥٦٧٨",
    additionalInformation: "(هەڵبژاردە) زانیاری زیاتر",
    additionalInformationPlaceholder: "هەر زانیاری زیاتر...",
    deleting: "...سڕینەوە",
    uploadIdDocument: "بەڵگەنامەکان",
    uploadIdDocumentNote: "(PDF, JPG یان PNG و لە خوارەوەی ١٠MB)",
    acceptedIdDocuments: ":بەڵگەنامەی وەرگرتنی پەیوەندیدار",
    birthCertificate: "بڕوانامەی لەدایکبوون",
    drivingLicense: "مۆڵەتی شۆفێری",
    passport: "پاسپۆرت",
    otherId: "بەڵگەنامەی حکومی تر",
    deletingAllFiles: "...سڕینەوەی هەموو پەڕگەکان",
    uploadSupportingDocuments: "دۆکیومێنتی زیادە",
    uploadSupportingDocumentsNote:
      "(تا ٣ پەڕگە، PDF, JPG یان PNG و خوارەوەی ١٠MB)",
    uploaded: "بارکراوە",
    supportingDocuments: ":پەڕگە پشتیوانی",
    referenceLetters: "نامەی پشتیوانی",
    certificates: "بڕوانامەکان",
    proofOfIncome: "دلیل بۆ داهات",
    otherRelevantDocuments: "پەڕگە پەیوەندیدارەکانی تر",
    thankYouTitle: "!سوپاس",
    thankYouMessage: "داواکاریەکەت بە سەرکەوتوویی درووستکرا.",
  },
};

export const SPACES_FORM_LABELS = {
  en: {
    steps: [
      "Initial Information",
      "Spaces & Calendar",
      "Overview & Cost",
      "Payment",
    ],
    name: "Name *",
    namePlaceholder: "Mohammad Ahmed",
    phoneNumber: "Phone Number *",
    phoneNumberPlaceholder: "07912 345 678",
    purposeOfHire: "Purpose of Hire *",
    purposeOfHirePlaceholder: "Community gathering",
    numberOfPeople: "Number of People *",
    numberOfPeoplePlaceholder: "0",
    additionalInformation: "Additional Information (Optional)",
    additionalInformationPlaceholder: "Any extra details...",
    bookingOverview: "Booking Overview",
    overviewName: "Name:",
    overviewPhone: "Phone:",
    overviewPurpose: "Purpose:",
    overviewGuests: "Guests:",
    overviewSpacesSelected: "Spaces Selected:",
    overviewAdditionalInfo: "Additional Info:",
    overviewDatetime: "Datetime:",
    paymentType: "Payment Type *",
    deposit: "Deposit",
    fullPayment: "Full Payment",
    cashPayment: "Cash",
    total: "Total:",
    amountToPayNow: "Amount to pay now:",
    agreeToTerms: "I agree to the terms and conditions",
    viewTerms: "View Terms",
    thankYouTitle: "Thank You!",
    thankYouMessage: "Your booking was created successfully.",
    proceedToPayment: "Proceed to Payment",
    next: "Next",
    previous: "Previous",
  },
  ku: {
    steps: [
      "زانیاری سەرەتا",
      "بوشایی و کات ژمێر",
      "بینین و نرخی کۆی",
      "پارەدان",
    ],
    name: "* ناو",
    namePlaceholder: "محمد أحمد",
    phoneNumber: "* ژمارەی تەلەفون",
    phoneNumberPlaceholder: "٠٧٩١٢٣٤٥٦٧٨",
    purposeOfHire: "* ئامانجی کرێ",
    purposeOfHirePlaceholder: "کۆبونەوەی کۆمەڵایەتی",
    numberOfPeople: "* ژمارەی کەسان",
    numberOfPeoplePlaceholder: "٠",
    additionalInformation: "زانیاری زیاتر (هەڵبژاردە)",
    additionalInformationPlaceholder: "هەر زانیاری زیاتر...",
    bookingOverview: "کورتەی داواکاری",
    overviewName: "ناو:",
    overviewPhone: "تەلەفون:",
    overviewPurpose: "ئامانج:",
    overviewGuests: "مەزەرۆکان:",
    overviewSpacesSelected: "بوشاییە هەڵبژێردراوەکان:",
    overviewAdditionalInfo: "زانیاری زیاتر:",
    overviewDatetime: "کاتژمێر:",
    paymentType: "* جۆری پارەدان",
    deposit: "نیمپارە",
    fullPayment: "پارەدانی تەواو",
    cashPayment: "کاش",
    total: "کۆ:",
    amountToPayNow: "بری پارەی ئێستا:",
    agreeToTerms: "ڕازی بوم بە مەرجەکان",
    viewTerms: "بینینی مەرجەکان",
    thankYouTitle: "سوپاس!",
    thankYouMessage: "داواکاریەکەت بەسەرکەوتوویی تومارکرا.",
    proceedToPayment: "چوون بۆ پارەدان",
    next: "دواتر",
    previous: "پێشتر",
  },
};
