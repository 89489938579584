import React from "react";
import {
  Home,
  Settings,
  Newspaper,
  Calendar,
  Bookmark,
  GalleryVerticalEnd,
  AudioWaveform,
  Command,
  DollarSign,
  Book,
  SquareGanttChart,
} from "lucide-react";

import { NavMain } from "./nav_main";
import { NavProjects } from "./nav_projects";
import { NavUser } from "./nav_user";
import { TeamSwitcher } from "./team_switcher";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
} from "components/shadcn/Sidebar";

// This is sample data.
const data = {
  user: {
    name: "shadcn",
    email: "m@example.com",
    avatar: "/avatars/shadcn.jpg",
  },
  teams: [
    {
      name: "Acme Inc",
      logo: GalleryVerticalEnd,
      plan: "Enterprise",
    },
    {
      name: "Acme Corp.",
      logo: AudioWaveform,
      plan: "Startup",
    },
    {
      name: "Evil Corp.",
      logo: Command,
      plan: "Free",
    },
  ],
  navMain: [
    {
      title: "Home",
      url: "/#/dashboard/admin/home",
      icon: Home,
      isActive: true,
    },
    {
      title: "Ask Imaam",
      url: "/#/dashboard/admin/ask-imaam",
      icon: Book, // Replace with an appropriate icon if needed
      isActive: false,
    },
    {
      title: "Events",
      url: "/#/dashboard/admin/events",
      icon: Calendar,
      isActive: false,
    },
    {
      title: "Manage Mosque",
      url: "/#/dashboard/admin/manage-mosque",
      icon: SquareGanttChart, // Replace with an appropriate icon if needed
      isActive: false,
    },
    {
      title: "Payments",
      url: "/#/dashboard/admin/payments",
      icon: DollarSign, // Replace with an appropriate icon if needed
      isActive: false,
    },
    {
      title: "Settings",
      url: "#",
      icon: Settings,
      items: [
        {
          title: "General",
          url: "#",
        },
        {
          title: "Profile",
          url: "#",
        },
      ],
    },
  ],
  projects: [
    {
      name: "Recent Services",
      url: "#",
      icon: Bookmark,
    },
    {
      name: "Upcoming Events",
      url: "#",
      icon: Calendar,
    },
    {
      name: "Latest News",
      url: "#",
      icon: Newspaper,
    },
  ],
};

export function AppSidebar(props) {
  return (
    <Sidebar collapsible="icon" {...props}>
      <SidebarHeader>
        <TeamSwitcher teams={data.teams} />
      </SidebarHeader>
      <SidebarContent>
        <NavMain items={data.navMain} />
        <NavProjects projects={data.projects} />
      </SidebarContent>
      <SidebarFooter>
        <NavUser user={data.user} />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
